/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef } from 'react';

import { useSearchParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import classNames from 'classnames';

import Question from '../../Question';
import ReplyBox from '../../ReplyBox';
import Comment from '../../Comment';

import CommentService from '../../../services/CommentService';
import classes from './styles.module.scss';

export default function Comments({
  commentsSource,
  questions,
  user,
  refetchMeeting,
  videoRef,
  id,
  setIsSignUpPopupVisible,
  rewindVideoByComment,
  isAuthenticated,
}) {
  const [commentText, setCommentText] = useState('');
  const [commentsMode, setCommentsMode] = useState('engagements');

  const textareaRef = useRef();

  const [searchParams] = useSearchParams();

  const { mutate: createCommentMutation } = useMutation(
    CommentService.createComment,
    {
      onSuccess: () => {
        textareaRef.current.textContent = '';
        refetchMeeting();
      },
      onError: (error) => {
        console.log(error);
        // notifyError(error?.response?.data?.message);
      },
    }
  );

  const createComment = () => {
    if (commentText.trim()) {
      createCommentMutation({
        textContent: commentText,
        meetingId: id,
        meetingTime: videoRef.current?.currentTime,
      });
    }
  };

  useEffect(() => {
    if (searchParams.get('responses')) {
      setCommentsMode('responses');
    }
  }, [searchParams, setCommentsMode]);

  return (
    <div className={classes.Comments}>
      <div className={classes.container}>
        <header className={classes.header}>
          <div
            className={classNames(
              classes.engagements,
              commentsMode === 'engagements' && classes.active
            )}
            onClick={() => setCommentsMode('engagements')}
          >
            <span>Comments</span>
          </div>
          <div
            className={classNames(
              classes.responses,
              commentsMode === 'responses' && classes.active
            )}
            onClick={() => setCommentsMode('responses')}
          >
            <span>Question Responses</span>
          </div>
        </header>
        {commentsMode === 'engagements' && (
          <ReplyBox
            textareaRef={textareaRef}
            user={user}
            commentText={commentText}
            setCommentText={setCommentText}
            createComment={createComment}
            setIsSignUpPopupVisible={setIsSignUpPopupVisible}
          />
        )}
        {commentsMode === 'engagements' && (
          <div className={classes.commentsList}>
            {commentsSource?.Comments?.map((comment) => (
              <Comment
                key={comment.id}
                comment={comment}
                createCommentMutation={createCommentMutation}
                rewindVideoByComment={rewindVideoByComment}
                user={user}
                refetch={refetchMeeting}
                meetingId={id}
                isAuthenticated={isAuthenticated}
                setIsSignUpPopupVisible={setIsSignUpPopupVisible}
              />
            ))}
          </div>
        )}
        {commentsMode === 'responses' && (
          <>
            {questions.length ? (
              <div className={classes.questionsList}>
                {questions?.map((question) => (
                  <Question question={question} key={question.id} />
                ))}
              </div>
            ) : (
              <p className={classes.noQuestions}>
                There are no questions in this video
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
}
