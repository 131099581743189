import { useCallback, useContext } from 'react';

import { UiContext } from '../context/UiContext';

export default function useConfirmDelete(onDelete, type) {
  const { showConfirmActionModal } = useContext(UiContext);

  const handleDelete = useCallback(
    (item) => {
      showConfirmActionModal({
        message: `Are you sure you want to delete this ${type}?`,
        onConfirm: () => onDelete(item.id),
      });
    },
    [onDelete, showConfirmActionModal, type]
  );

  return handleDelete;
}
