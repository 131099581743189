import React from 'react';

import Modal from 'react-bootstrap/Modal';
import { useQuery } from 'react-query';
import { Link, useLocation } from 'react-router-dom';

import moment from 'moment-timezone';

import closeIcon from '../../assets/images/closeIcon.svg';

import localTimeZone from '../../helpers/localTimeZone';
import formatDuration from '../../helpers/formatDuration';
import FolderService from '../../services/FolderService';
import classes from './styles.module.scss';

const timezoneOffset = moment.tz(Date.now(), localTimeZone).format('Z');

export default function MeetingDetailsModal({ show, handleClose, meeting }) {
  const { pathname } = useLocation();

  const isLibrary = pathname.startsWith('/my-library');

  const { data: folder } = useQuery(
    ['folder', meeting?.folderId],
    () => FolderService.getFolder(meeting?.folderId),
    {
      enabled: !!meeting?.folderId && !isLibrary,
    }
  );

  if (!meeting) {
    return null;
  }

  const isMeetingExpired =
    meeting.expiresAt && new Date(meeting.expiresAt) < new Date();

  let sharedDate = '-';
  let expirationInfo;

  if (isMeetingExpired) {
    expirationInfo = 'Video expired';
  } else if (!meeting.expiresAt) {
    expirationInfo = '-';
  } else {
    expirationInfo = `${moment(meeting.expiresAt).format(
      'M-D-YY h:mm A'
    )} (UTC${timezoneOffset}) ${localTimeZone.split('/')[1]}`;
  }

  if (meeting.plannedToShareAt) {
    sharedDate = moment(meeting.plannedToShareAt).format('M-D-YY');
  }

  if (meeting.sharedAt) {
    sharedDate = moment(meeting.sharedAt).format('M-D-YY');
  }

  const duration = formatDuration(meeting.duration);

  const isFileLocationVisible = !isLibrary;

  const isNotAllowedToCheckVersions = meeting.status === 'in progress';

  return (
    <Modal
      show={show}
      centered
      contentClassName={classes.MeetingDetailsModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      <i className={classes.closeIcon} onClick={handleClose}>
        <img src={closeIcon} alt="Close" />
      </i>
      <div className={classes.content}>
        <p>Title: {meeting.title}</p>
        <p>
          Owner: {meeting.ownerName || meeting.Owner.name},{' '}
          {meeting.ownerTitle || meeting.Owner.title}
        </p>
        {sharedDate !== '-' && <p>Published: {sharedDate}</p>}
        {expirationInfo !== '-' && <p>Expiration: {expirationInfo}</p>}
        {meeting.version && (
          <p>
            Version:{' '}
            {isNotAllowedToCheckVersions ? (
              `${meeting.version}`
            ) : (
              <Link to={`/my-videos/${meeting.id}/history`}>
                {meeting.version}
              </Link>
            )}
          </p>
        )}
        {meeting.duration && <p>Duration: {duration}</p>}
        {isFileLocationVisible && (
          <p>
            File Location:{' '}
            <Link to={`/my-library/${meeting.folderId}`}>
              {folder?.data.name}
            </Link>
          </p>
        )}
        {!!meeting?.MeetingReviews?.length && (
          <p>
            <>
              Approved by:
              <br />
              {meeting.MeetingReviews.map((review) => (
                <>
                  {review.User
                    ? `${review.User.name}, ${review.User.title}`
                    : review.email}
                  <br />
                </>
              ))}
            </>
          </p>
        )}
      </div>
    </Modal>
  );
}
