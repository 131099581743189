import React from 'react';

import { NavLink, useLocation } from 'react-router-dom';

import classes from './styles.module.scss';

export default function Navigation() {
  const location = useLocation();

  const isMeetingEditing = location.pathname.includes('/edit');
  const isMeetingHistory = location.pathname.includes('/history');

  return (
    <nav className={classes.Navigation}>
      <div className={classes.linksContainer}>
        <NavLink
          className={({ isActive }) =>
            isActive && !isMeetingEditing && !isMeetingHistory && classes.active
          }
          to="/my-videos"
        >
          My Videos
        </NavLink>

        <NavLink
          className={({ isActive }) => isActive && classes.active}
          to="/my-library"
        >
          My Library
        </NavLink>
      </div>
    </nav>
  );
}
