import React, { useContext, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { UiContext } from '../../context/UiContext';
import { hasAuthData } from '../../helpers/authStorage';

export default function PrivateRoute({ children }) {
  const isAuthenticated = hasAuthData();
  const location = useLocation();

  const { setRedirectAfterLogin } = useContext(UiContext);

  useEffect(() => {
    if (!isAuthenticated) {
      setRedirectAfterLogin(location.pathname + location.search);
    }
  }, [isAuthenticated, location]);
  return isAuthenticated ? children : <Navigate to="/sign-in" />;
}
