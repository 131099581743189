import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import CancelButton from '../../components/Buttons/CancelButton';
import ConfirmButton from '../../components/Buttons/ConfirmButton';

import classes from './styles.module.scss';
import RoundedInput from '../../components/Inputs/RoundedInput';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

export default function UpdateMeetingTitleModal({
  show,
  handleClose,
  onConfirm,
  title,
}) {
  const [newTitle, setNewTitle] = useState('');

  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    setNewTitle(title);
  }, [title, show]);

  const onUpdatingTitleCancel = () => {
    if (isMobile) {
      onConfirm('Untitled video');
      return;
    }

    if (title.length) {
      handleClose();
    } else {
      navigate('/my-videos');
    }
  };

  const isNewTitleEmpty = !newTitle.trim().length;

  return (
    <Modal
      show={show}
      centered
      contentClassName={classes.UpdateMeetingTitleModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      <div className={classes.content}>
        <h2 className={classes.title}>Give your video a name</h2>
        <RoundedInput
          value={newTitle}
          onChange={(event) => setNewTitle(event.target.value)}
          placeholder="Untitled video"
        />

        <div className={classes.buttons}>
          <CancelButton onClick={onUpdatingTitleCancel} />

          <ConfirmButton
            onClick={() => {
              onConfirm(newTitle);
              handleClose();
            }}
            isDisabled={isNewTitleEmpty}
          />
        </div>
      </div>
    </Modal>
  );
}
