import React from 'react';

import classes from './styles.module.scss';

export default function ConfirmButton({ isDisabled, onClick }) {
  return (
    <button
      type="submit"
      disabled={isDisabled}
      className={classes.ConfirmButton}
      onClick={onClick}
    >
      <svg
        width="117"
        height="135"
        viewBox="0 0 117 135"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_122_1106)">
          <circle cx="58.5" cy="42.5" r="42.5" fill="#F8585C" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M78.2147 37.7101C78.7315 37.1791 79.0129 36.4769 78.9995 35.7513C78.9862 35.0258 78.6791 34.3335 78.143 33.8203C77.6069 33.3072 76.8837 33.0132 76.1256 33.0004C75.3676 32.9876 74.634 33.257 74.0793 33.7517L53.5569 53.2434L43.9207 44.0196C43.366 43.5249 42.6324 43.2556 41.8744 43.2684C41.1163 43.2812 40.3931 43.5751 39.857 44.0883C39.3209 44.6014 39.0138 45.2937 39.0005 46.0193C38.9871 46.7449 39.2685 47.4471 39.7853 47.978L51.4892 59.1809C52.0378 59.7054 52.7815 60 53.5569 60C54.3323 60 55.0759 59.7054 55.6246 59.1809L78.2147 37.7101Z"
          fill="white"
        />
        <defs>
          <filter
            id="filter0_d_122_1106"
            x="0"
            y="0"
            width="117"
            height="135"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="18"
              operator="erode"
              in="SourceAlpha"
              result="effect1_dropShadow_122_1106"
            />
            <feOffset dy="34" />
            <feGaussianBlur stdDeviation="17" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0.296667 0 0 0 0 0.741667 0 0 0 0.08 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_122_1106"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_122_1106"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </button>
  );
}
