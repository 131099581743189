import React from 'react';
import Modal from 'react-bootstrap/Modal';

import closeIcon from '../../assets/images/closeIcon.svg';

import CancelButton from '../../components/Buttons/CancelButton';
import ConfirmButton from '../../components/Buttons/ConfirmButton';

import classes from './styles.module.scss';

export default function ConfirmActionModal({
  title = 'Whoa wait!',
  show,
  handleClose,
  message,
  onConfirm,
  noButtons,
}) {
  return (
    <Modal
      show={show}
      centered
      contentClassName={classes.ConfirmActionModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      <i className={classes.closeIcon} onClick={handleClose}>
        <img src={closeIcon} alt="Close" />
      </i>
      <div className={classes.content}>
        <h3>{title}</h3>
        <p>{message}</p>
        {!noButtons && (
          <div className={classes.buttons}>
            <CancelButton onClick={handleClose} />

            <ConfirmButton
              onClick={() => {
                onConfirm();
                handleClose();
              }}
            />
          </div>
        )}
      </div>
    </Modal>
  );
}
