import React from 'react';

import Modal from 'react-bootstrap/Modal';
import { useQuery } from 'react-query';

import userIcon from '../../assets/images/usericon.webp';
import closeIcon from '../../assets/images/closeIcon.svg';

import { STATIC_URL, USER_ROLES } from '../../constants/main';
import FolderService from '../../services/FolderService';
import ManageUsersMenu from '../../components/Library/ManageUsersMenu';
import FilledButton from '../../components/Buttons/FilledButton';
import classes from './styles.module.scss';

export default function FolderUsersModal({
  show,
  handleClose,
  folder,
  handleShare,
  user,
  refetchFolders,
}) {
  const { data: folderUsers, refetch } = useQuery(
    ['folderUsers', folder, show],
    () => FolderService.getFolderUsers(folder.id),
    {
      keepPreviousData: true,
      enabled: !!folder,
    }
  );

  const isShareButtonDisabled =
    user?.id !== folder?.userId && user?.role !== USER_ROLES.SUPER_ADMIN;

  return (
    <Modal
      show={show}
      centered
      contentClassName={classes.FolderUsersModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      <i className={classes.closeIcon} onClick={handleClose}>
        <img src={closeIcon} alt="Close" />
      </i>
      <div className={classes.content}>
        <h2>{folder?.name}</h2>
        <div className={classes.usersContainer}>
          <h3>People with access</h3>
          <ul className={classes.usersList}>
            {!!folderUsers?.length &&
              folderUsers.map((folderUser) => (
                <li key={folderUser.id} className={classes.userItem}>
                  <div className={classes.userInfoContainer}>
                    <img
                      src={`${
                        folderUser.User.photoPath
                          ? STATIC_URL + folderUser.User.photoPath
                          : userIcon
                      }`}
                      alt="User"
                    />
                    <div className={classes.userInfo}>
                      <span className={classes.userName}>
                        {folderUser.User.name}
                      </span>
                      <span className={classes.userTitle}>
                        {folderUser.User.title}
                      </span>
                      <a
                        className={classes.userEmail}
                        href={`mailto:${folderUser.User.email}`}
                      >
                        {folderUser.User.email}
                      </a>
                    </div>
                  </div>
                  <ManageUsersMenu
                    folderUser={folderUser}
                    refetchFolderUsers={refetch}
                    displayedValue={folderUser.userRole}
                    user={user}
                    handleClose={handleClose}
                    refetchFolders={refetchFolders}
                    folder={folder}
                  />
                </li>
              ))}
          </ul>
        </div>
        <FilledButton
          isDisabled={isShareButtonDisabled}
          onClick={() => {
            handleShare(folder);
            handleClose();
          }}
        >
          Share Folder
        </FilledButton>
      </div>
    </Modal>
  );
}
