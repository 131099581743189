/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  useLayoutEffect,
} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

import classNames from 'classnames';

import updateTitleIcon from '../../assets/images/my-meetings/edit.svg';
import updateVideoIcon from '../../assets/images/create-meeting/uploadVideo.svg';
import ShareModal from '../../modals/ShareModal';
import CopyLinkModal from '../../modals/CopyLinkModal';
import SelectTimeZoneModal from '../../modals/SelectTimeZoneModal';
import Tooltip from '../../components/Tooltip';
import CreateMeetingFooter from '../../components/CreateMeetingFooter';
import MediaContainer from '../../components/MediaContainer';
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import { UiContext } from '../../context/UiContext';
import MeetingService from '../../services/MeetingService';
import useResizeObserver from '../../hooks/useResizeObserver';
import classes from './styles.module.scss';
import { MEETING_STATUSES } from '../../constants/main';
import UserService from '../../services/UserService';
import SectionService from '../../services/SectionService';
import localTimeZone from '../../helpers/localTimeZone';
import UpdateMeetingTitleModal from '../../modals/UpdateMeetingTitleModal';
import InfoIcon from '../../components/InfoIcon';
import PublishMeetingModal from '../../modals/PublishMeetingModal';
import SelectShareOptionModal from '../../modals/SelectShareOptionModal';
import RequestReviewModal from '../../modals/RequestReviewModal';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import AlertModal from '../../modals/AlertModal';
import { SocketContext } from '../../context';

let chunks;
let mediaStreams = [];
let noContentWarningTimer;

export default function CreateOrUpdateMeetingPage() {
  const [
    isUpdateMeetingTitleModalVisible,
    setIsUpdateMeetingTitleModalVisible,
  ] = useState(false);
  const [meetingTitle, setMeetingTitle] = useState('');
  const [isCameraEnabled, setIsCameraEnabled] = useState(false);
  const [isScreenEnabled, setIsScreenEnabled] = useState(false);
  const [sections, setSections] = useState(null);
  const [isSaveSectionPopupVisible, setIsSaveSectionPopupVisible] =
    useState(false);
  const [isConfirmSectionRemovalVisible, setIsConfirmSectionRemovalVisible] =
    useState(false);
  const [currentSectionsPage, setCurrentSectionsPage] = useState(0);
  const [sectionsPageCount, setSectionPageCount] = useState(0);
  const [currentSectionId, setCurrentSectionId] = useState(null);
  const [videoPlayerSource, setVideoPlayerSource] = useState('stream');
  const [isShareMeetingModalVisible, setIsShareMeetingModalVisible] =
    useState(false);
  const [meetingId, setMeetingId] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [file, setFile] = useState(null);
  const [firstPlaceholderTitle, setFirstPlaceholderTitle] = useState(false);
  const [isQuestionFormVisible, setIsQuestionFormVisible] = useState(false);
  const [question, setQuestion] = useState(null);
  const [fetchedQuestionData, setFetchedQuestionData] = useState(null);
  const [isAssignSectionFormVisible, setIsAssignSectionFormVisible] =
    useState(false);
  const [cameraHasStopped, setCameraHasStopped] = useState(false);
  // const [screenHasStopped, setScreenHasStopped] = useState(false);
  const [isPublishMeetingPopupVisible, setIsPublishMeetingPopupVisible] =
    useState(false);
  const [isNoTitleWarningVisible, setIsNoTitleWarningVisible] = useState(false);
  const [isSectionsCompletePopupVisible, setIsSectionsCompletePopupVisible] =
    useState(false);
  const [isAudioEnabled, setIsAudioEnabled] = useState(false);
  const [isScreenRecordReminderVisible, setIsScreenRecordReminderVisible] =
    useState(false);
  const [isShareSuccessPopupVisible, setIsShareSuccessPopupVisible] =
    useState(false);
  const [isPreparingPreview, setIsPreparingPreview] = useState(false);
  const [isNoContentWarningPopupVisible, setIsNoContentWarningPopupVisible] =
    useState(false);
  const [audioImage, setAudioImage] = useState(null);
  const [sectionTitle, setSectionTitle] = useState('');
  const [isSelectTimeZoneModalVisible, setIsSelectTimeZoneModalVisible] =
    useState(false);
  const [timeZoneAssignment, setTimeZoneAssignment] = useState('');
  const [isSectionCreationStopped, setIsSectionCreationStopped] =
    useState(false);
  const [selectedTimeZone, setSelectedTimeZone] = useState({
    schedule: {
      label: '',
      value: localTimeZone,
    },
    expiration: {
      label: '',
      value: localTimeZone,
    },
  });
  const [isCopyLinkModalVisible, setIsCopyLinkModalVisible] = useState(false);
  const [sharedMeetingAccess, setSharedMeetingAccess] = useState({
    link: '',
    passcode: '',
    security: '',
  });
  const [isPublishMeetingModalVisible, setIsPublishMeetingModalVisible] =
    useState(false);
  const [isSelectShareOptionModalVisible, setIsSelectShareOptionModalVisible] =
    useState(false);
  const [isRequestReviewModalVisible, setIsRequestReviewModalVisible] =
    useState(false);
  const [isSectionSavedModalVisible, setIsSectionSavedModalVisible] =
    useState(false);
  const [processingProgress, setProcessingProgress] = useState({
    sectionId: '',
    percentage: 0,
  });

  const videoSectionPlayerRef = useRef();
  const streamPreviewMediaPlayerRef = useRef();
  const mediaRecorderRef = useRef();
  const innerContainerRef = useRef();
  const streamRef = useRef();
  const videoPlayerRef = useRef();
  const videoPlayerContainerRef = useRef();
  const fileInputRef = useRef();

  const navigate = useNavigate();
  const { socket } = useContext(SocketContext);

  const { width } = useResizeObserver(innerContainerRef);
  const isMobile = useCheckMobileScreen();

  const { notifyError, showConfirmActionModal } = useContext(UiContext);

  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const isShare = searchParams.get('share');
  const areSectionsFetchedAlready = useRef(false);
  const folderId = searchParams.get('folderId');

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);

  useEffect(() => {
    if (isShare === 'true') {
      setIsSelectShareOptionModalVisible(true);
    }
  }, [isShare]);

  useEffect(() => {
    if (isMobile) {
      return;
    }

    if (id) {
      setMeetingId(id);
    } else {
      setIsUpdateMeetingTitleModalVisible(true);
    }
  }, [id]);

  const { data: user } = useQuery('me', UserService.getMe);

  const { data: fetchedMeeting, refetch: refetchMeeting } = useQuery(
    ['meeting', meetingId],
    () => {
      return MeetingService.getMeeting(meetingId);
    },
    {
      enabled: !!meetingId,
      keepPreviousData: true,
    }
  );

  const { data: fetchedSections, refetch } = useQuery(
    ['sections', meetingId],
    () => {
      return SectionService.getSections({ meetingId });
    },
    {
      enabled: !!meetingId,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (fetchedMeeting) {
      setMeetingId(fetchedMeeting.id);
    }
  }, [fetchedMeeting]);

  useEffect(() => {
    if (fetchedSections) {
      setSections(fetchedSections);
      if (areSectionsFetchedAlready.current) {
        const lastSection = fetchedSections[fetchedSections.length - 1];
        if (lastSection) {
          //  setVideoPlayerSource(lastSection);
          setCurrentSectionId(lastSection.id);
        }
      } else {
        areSectionsFetchedAlready.current = true;
        const firstSection = fetchedSections[0];
        if (firstSection) {
          //  setVideoPlayerSource(firstSection);
          setCurrentSectionId(firstSection.id);
        }
      }
    }
  }, [fetchedSections]);

  useEffect(() => {
    if (fetchedSections) {
      const section = fetchedSections.find(
        (sect) => sect.id === currentSectionId
      );

      if (section) {
        if (section.type === 'question') {
          setFetchedQuestionData(section?.Question);
          // setIsQuestionFormVisible(true);
        } else {
          setVideoPlayerSource(section);
          setIsQuestionFormVisible(false);
        }
      }
    }
  }, [currentSectionId, fetchedSections]);

  useEffect(() => {
    const getSectionProcessing = ({ sectionId, percentage }) => {
      setProcessingProgress({ sectionId, percentage });

      if (percentage === 100) {
        refetch();
        setProcessingProgress({ sectionId: '', percentage: 0 });
      }
    };

    socket.on('processing', getSectionProcessing);

    return () => {
      socket.removeListener('processing', getSectionProcessing);
    };
  }, [processingProgress, refetch, socket]);

  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const sectionsCount = Math.floor(width / (232 + 40)) || 1;

  const showPlaceholder = useCallback(
    (title, placeholderType) => {
      if (!sections) {
        setFirstPlaceholderTitle(placeholderType);
      } else {
        setSections((prevSections) => [
          ...prevSections,
          {
            id: Math.random(),
            path: 'placeholder',
            title: title || 'Section Placeholder',
            placeholderType,
          },
        ]);
      }
    },
    [sections]
  );

  const hidePlaceholder = useCallback(() => {
    if (!sections) {
      setFirstPlaceholderTitle(false);
    } else {
      setSections((prevSections) =>
        prevSections.filter((section) => section.path !== 'placeholder')
      );
    }
  }, [sections]);

  const stopAllTracks = useCallback(() => {
    mediaStreams.forEach((mediaRecorder) =>
      mediaRecorder.getTracks().forEach((track) => {
        track.stop();
      })
    );
    mediaStreams = [];
  }, []);

  const stopVideoPlayer = useCallback(() => {
    if (videoPlayerSource === 'stream' || videoPlayerSource === 'audio') {
      setIsRecording(false);

      if (mediaRecorderRef.current?.state === 'recording') {
        mediaRecorderRef.current.stop();
      } else {
        streamRef.current?.getTracks().forEach((track) => {
          track.stop();
        });
        setIsCameraEnabled(false);
        setIsScreenEnabled(false);
        setIsAudioEnabled(false);
        hidePlaceholder();
        setVideoPlayerSource(sections ? sections[sections.length - 2] : null);
      }

      stopAllTracks();

      if (isCameraEnabled) {
        setCameraHasStopped(true);
      }
      // else if (isScreenEnabled) {
      //   // setScreenHasStopped(true);
      // }
    } else if (videoSectionPlayerRef.current) {
      videoSectionPlayerRef.current.pause();
      videoSectionPlayerRef.current.currentTime = 0;
    }
  }, [
    hidePlaceholder,
    isCameraEnabled,
    // isScreenEnabled,
    sections,
    stopAllTracks,
    videoPlayerSource,
  ]);

  const { mutate: deleteMeetingMutation } = useMutation(
    MeetingService.deleteMeeting,
    {
      onSuccess: () => {
        navigate('/');
      },
    }
  );

  const { mutate: createSection } = useMutation(SectionService.createSection, {
    onSuccess: (section) => {
      if (section.type === 'video' || section.type === 'audio') {
        setFile(null);
        setAudioImage(null);
      } else if (section.type === 'question') {
        setIsQuestionFormVisible(false);
        setQuestion(null);
      }

      // setSaveSectionSuccessMessage('Section Successfully Saved!');
      // setIsSaveSectionPopupVisible(true);
      refetch();
      setFirstPlaceholderTitle(false);
      if (fetchedSections?.length) {
        refetchMeeting();
      }
    },
  });

  const { mutate: updateSection } = useMutation(SectionService.updateSection, {
    onSuccess: () => {
      refetchMeeting();
      refetch();
    },
  });

  const { mutate: deleteSectionMutation } = useMutation(
    SectionService.deleteSection,
    {
      onSuccess: (section) => {
        if (section.id === videoPlayerSource.id) {
          const newSource = sections.filter(
            (sctn) => sctn.id !== section.id
          )?.[0];
          setVideoPlayerSource(newSource || null);
        }

        refetch();
        refetchMeeting();
        setCurrentSectionId(null);
        setIsConfirmSectionRemovalVisible(false);
      },
    }
  );

  const deleteSection = useCallback(() => {
    const sectionId = currentSectionId || sections[sections.length - 1]?.id;
    deleteSectionMutation({ sectionId });
  }, [currentSectionId, deleteSectionMutation, sections]);

  const { mutate: createMeeting } = useMutation(MeetingService.createMeeting, {
    onSuccess: (meeting) => {
      setMeetingId(meeting.id);
      createSection({
        meetingId: meeting.id,
        file,
        question,
        container: isSafari ? 'mp4' : 'webm',
        title: sectionTitle,
        image: audioImage,
      });
      setFile(null);

      if (isMobile) {
        return;
      }

      setQuestion(null);
      setIsQuestionFormVisible(false);
      navigate(`/my-videos/${meeting.id}/edit`, {
        replaceState: true,
      });
    },
    onError: (error) => {
      console.log(error);
      notifyError(error?.response?.data?.message);
    },
  });

  const { mutateAsync: createMeetingAsync } = useMutation(
    MeetingService.createMeeting
  );

  const startCameraMediaRecorder = useCallback(() => {
    setVideoPlayerSource('stream');

    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: {
          width: { ideal: 1920, max: 1920 },
          height: { ideal: 1080, max: 1080 },
        },
      })
      .then((stream) => {
        streamRef.current = stream;
        videoPlayerRef.current.srcObject = stream;
        videoPlayerRef.current.onloadedmetadata = () => {
          if (!videoPlayerRef.current) {
            return;
          }
          videoPlayerRef.current.play();
          setIsCameraEnabled(true);
        };

        mediaStreams.push(stream);

        let mimeType = 'video/webm;codecs=vp9';
        if (isFirefox) {
          mimeType = 'video/webm;codecs:vp9';
        } else if (isSafari) {
          mimeType = 'video/mp4';
        }

        const options = {
          mimeType,
        };
        mediaRecorderRef.current = new MediaRecorder(stream, options);
        chunks = [];

        mediaRecorderRef.current.addEventListener('dataavailable', (e) => {
          if (e.data.size > 0) {
            chunks.push(e.data);
          }
        });

        mediaRecorderRef.current.addEventListener('stop', () => {
          stream.getTracks().forEach((track) => {
            track.stop();
          });

          const blob = new Blob(chunks, {
            type: 'video/webm',
          });

          setIsCameraEnabled(false);
          setFile(blob);
        });
      })
      .catch(() => hidePlaceholder());
  }, [hidePlaceholder, isFirefox, isSafari]);

  const startScreenMediaRecorder = useCallback(
    (callback) => {
      setVideoPlayerSource('stream');

      navigator.mediaDevices
        .getDisplayMedia({
          // audio: true,
          video: {
            mediaSource: 'screen',
            width: { ideal: 1920, max: 1920 },
            height: { ideal: 1080, max: 1080 },
          },
        })
        .then(async (stream) => {
          const audio = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false,
          });

          const combinedStream = new MediaStream([
            ...stream.getTracks(),
            ...audio.getTracks(),
          ]);

          mediaStreams.push(stream);

          streamRef.current = combinedStream;
          videoPlayerRef.current.srcObject = stream;
          videoPlayerRef.current.onloadedmetadata = () => {
            if (!videoPlayerRef.current) {
              return;
            }
            videoPlayerRef.current.play();
            setIsScreenEnabled(true);
          };

          let mimeType = 'video/webm;codecs=vp9';
          if (isFirefox) {
            mimeType = 'video/webm;codecs:vp9';
          } else if (isSafari) {
            mimeType = 'video/mp4';
          }

          const options = {
            mimeType,
          };
          mediaRecorderRef.current = new MediaRecorder(combinedStream, options);
          chunks = [];

          if (callback) {
            callback();
          }

          mediaRecorderRef.current.addEventListener('dataavailable', (e) => {
            if (e.data.size > 0) {
              chunks.push(e.data);
            }
          });

          mediaRecorderRef.current.addEventListener('stop', () => {
            combinedStream.getTracks().forEach((track) => {
              track.stop();
            });

            const blob = new Blob(chunks, {
              type: 'video/webm',
            });
            setIsScreenEnabled(false);
            setFile(blob);
          });
        })
        .catch(() => hidePlaceholder());
    },
    [hidePlaceholder, isFirefox, isSafari]
  );

  const startAudioMediaRecorder = useCallback(() => {
    setVideoPlayerSource('audio');

    navigator.mediaDevices
      .getUserMedia({
        audio: true,
      })
      .then((stream) => {
        streamRef.current = stream;
        mediaStreams.push(stream);

        const mimeType = isSafari ? 'audio/mp4' : 'audio/webm';
        const options = {
          mimeType,
        };

        mediaRecorderRef.current = new MediaRecorder(stream, options);
        chunks = [];

        setIsAudioEnabled(true);

        mediaRecorderRef.current.addEventListener('dataavailable', (e) => {
          if (e.data.size > 0) {
            chunks.push(e.data);
          }
        });

        mediaRecorderRef.current.addEventListener('stop', () => {
          stream.getTracks().forEach((track) => {
            track.stop();
          });

          const blob = new Blob(chunks, {
            type: 'audio/webm',
          });

          setIsAudioEnabled(false);
          setFile(blob);
        });
      })
      .catch(() => hidePlaceholder());
  }, [hidePlaceholder, isSafari]);

  const startScreen = useCallback(
    (callback) => {
      setIsRecording(false);
      startScreenMediaRecorder(callback);
      setIsCameraEnabled(false);
      setIsAudioEnabled(false);
    },
    [startScreenMediaRecorder]
  );

  const startVideo = useCallback(() => {
    setIsRecording(false);
    startCameraMediaRecorder();
    setIsScreenEnabled(false);
    setIsAudioEnabled(false);
  }, [startCameraMediaRecorder]);

  const startAudio = useCallback(() => {
    setIsRecording(false);
    startAudioMediaRecorder();
    setIsScreenEnabled(false);
    setIsCameraEnabled(false);
  }, [startAudioMediaRecorder]);

  const { mutate: updateMeeting } = useMutation(MeetingService.updateMeeting, {
    onSuccess: (meeting) => {
      if (meeting.status !== 'in progress') {
        setMeetingId(meeting.id);
        refetchMeeting();
      } else {
        setTimeout(() => {
          if (isMobile) {
            navigate('/my-videos?tab=mobile uploads');
          } else {
            navigate(
              meeting.folderId
                ? `/my-library/${meeting.folderId}`
                : '/my-videos'
            );
          }
        }, 5000);
      }

      setIsPublishMeetingPopupVisible(true);
    },
    onError: (error) => {
      console.log(error);

      notifyError(error?.response?.data?.message);
    },
  });

  const { mutateAsync: updateMeetingAsync } = useMutation(
    MeetingService.updateMeeting
  );

  const showQuestionForm = useCallback(() => {
    setIsQuestionFormVisible(true);
    setFetchedQuestionData(null);
  }, []);

  const showAssignSectionForm = useCallback(() => {
    setIsAssignSectionFormVisible(true);
  }, []);

  const hideAssignSectionForm = useCallback(() => {
    setIsAssignSectionFormVisible(false);
  }, []);

  const closeAssignedSection = async () => {
    try {
      await MeetingService.closeAssignedSection(meetingId);
      setIsSectionsCompletePopupVisible(true);
      setTimeout(() => {
        navigate('/my-videos');
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  const hideQuestionAndAssignSectionForms = useCallback(() => {
    setIsAssignSectionFormVisible(false);
    setIsQuestionFormVisible(false);
  }, []);

  const updateMeetingTitle = (title) => {
    if (!fetchedMeeting) {
      setMeetingTitle(title);

      return;
    }

    setMeetingTitle(title);
    updateMeetingAsync({
      meetingId: fetchedMeeting.id,
      title,
    });

    if (isMobile) {
      updateMeeting({
        meetingId,
        title: meetingTitle,
        status: MEETING_STATUSES.PUBLISHED,
      });
      setTimeout(() => {
        navigate('/my-videos?tab=mobile uploads');
      }, 500);
    }
  };

  const previewMeeting = async () => {
    setIsPreparingPreview(true);
    await MeetingService.previewMeeting(meetingId);
    setIsPreparingPreview(false);
    navigate(`/my-videos/${meetingId}/preview`);
  };

  useEffect(() => {
    setSectionPageCount(Math.ceil((sections?.length + 1) / sectionsCount));
  }, [sections, sectionsCount]);

  useEffect(() => {
    setCurrentSectionsPage(0);
  }, [sectionsPageCount]);

  // Load empty video source into video section preview if section was deleted
  useEffect(() => {
    if (videoPlayerSource === null) {
      videoSectionPlayerRef.current?.load();
    }
  }, [videoPlayerSource]);

  useEffect(() => {
    if (fetchedMeeting && fetchedMeeting.status !== 'in progress') {
      setMeetingTitle(fetchedMeeting.title);
    }
  }, [fetchedMeeting]);

  // Autosave when there is no meeting created (for video sections)
  useEffect(() => {
    if (!sections && !meetingId && file) {
      createMeeting({
        title: meetingTitle,
        folderId,
        type: isMobile ? 'mobile uploads' : 'my videos',
      });
    }
  }, [
    sections,
    meetingId,
    createMeeting,
    meetingTitle,
    file,
    folderId,
    isMobile,
  ]);

  // Autosave when there is no meeting created (for question sections)
  useEffect(() => {
    if (!sections && !meetingId && question) {
      createMeeting({
        title: meetingTitle,
        folderId,
        type: isMobile ? 'mobile uploads' : 'my videos',
      });
    }
  }, [
    sections,
    meetingId,
    createMeeting,
    meetingTitle,
    question,
    folderId,
    isMobile,
  ]);

  // Autosave after meeting was created (for video sections)
  useEffect(() => {
    if (isSectionCreationStopped) {
      setFile(null);
      return;
    }

    if (meetingId && file && sections) {
      createSection({
        title: sectionTitle,
        meetingId,
        file,
        container: isSafari ? 'mp4' : 'webm',
        image: audioImage,
      });
    }
  }, [
    audioImage,
    createSection,
    file,
    isSafari,
    isSectionCreationStopped,
    meetingId,
    sectionTitle,
    sections,
  ]);

  // Autosave after meeting was created (for question sections)
  useEffect(() => {
    if (meetingId && question && sections) {
      createSection({
        meetingId,
        question,
        title: sectionTitle,
      });
    }
  }, [createSection, question, isSafari, meetingId, sections, sectionTitle]);

  useEffect(() => {
    if (isNoTitleWarningVisible) {
      setTimeout(() => {
        setIsNoTitleWarningVisible(false);
      }, 3000);
    }
  }, [isNoTitleWarningVisible]);

  const isPublishButtonDisabled =
    fetchedMeeting?.userRole !== 'creator' || !sections?.length;
  const isShareButtonDisabled =
    fetchedMeeting?.status !== MEETING_STATUSES.PUBLISHED ||
    fetchedMeeting?.userRole !== 'creator';
  const isDeleteButtonDisabled =
    !meetingId || fetchedMeeting?.userRole !== 'creator';
  const isPreviewButtonDisabled = !sections?.length || isPreparingPreview;
  const isSectionButtonDisabled = useMemo(
    () =>
      sections?.filter(
        (section) =>
          section.assignedUserId === user?.id || section.userId === user?.id
      ).length < 2,
    [sections, user]
  );

  const openCopyLinkModal = ({ link, passcode, security }) => {
    setIsShareMeetingModalVisible(false);
    setIsCopyLinkModalVisible(true);
    setSharedMeetingAccess({ link, passcode, security });
  };

  const createDefaultSectionName = useCallback(
    (section) => {
      const type = section.placeholderType;

      const previousSectionOfSameType = sections?.filter(
        (sect) => sect.type === type
      );

      const capitalizedType = type.charAt(0).toUpperCase() + type.slice(1);

      return `${capitalizedType} ${
        previousSectionOfSameType ? previousSectionOfSameType.length + 1 : 1
      }`;
    },
    [sections]
  );

  return (
    <div className={classes.CreateOrUpdateMeetingPage}>
      <Header hasUserBlock />
      <div className={classes.navigationContainer}>
        <Navigation />
      </div>
      <div className={classes.container}>
        <button
          onClick={() => {
            if (!isMobile) {
              return;
            }

            const title = createDefaultSectionName({
              placeholderType: 'video',
            });

            setSectionTitle(title);
            fileInputRef.current.click();
          }}
          className={classes.uploadVideoButton}
          type="button"
        >
          <img src={updateVideoIcon} alt="Upload video" />
          <span>Upload from my device</span>
        </button>
        <div className={classes.innerContainer} ref={innerContainerRef}>
          <div className={classes.meetingForm}>
            <div className={classes.meetingInfo}>
              <div className={classes.meetingVersion}>
                <h2>Version {fetchedMeeting?.version || '1'}</h2>
                <InfoIcon
                  text="Documents file revision number since last edits were made"
                  width={320}
                />
              </div>
              <div className={classes.meetingTitle}>
                <h2>{meetingTitle}</h2>
                {(fetchedMeeting?.userRole === 'creator' ||
                  (!id && meetingTitle)) && (
                  <button
                    className={classes.updateTitle}
                    type="button"
                    onClick={() => {
                      setIsUpdateMeetingTitleModalVisible(true);
                    }}
                  >
                    <img src={updateTitleIcon} alt="Update title" />
                  </button>
                )}
              </div>
            </div>
            <div className={classes.buttons}>
              <Tooltip
                text="Playback your entire video as other people would view it"
                width={300}
              >
                <button
                  onClick={() => {
                    if (isPreviewButtonDisabled) {
                      return;
                    }
                    previewMeeting();
                  }}
                  //  disabled={isPreviewButtonDisabled}
                  type="button"
                  className={classNames(classes.saveButton, {
                    [classes.disabled]: isPreviewButtonDisabled,
                  })}
                >
                  Preview
                </button>
              </Tooltip>
              <Tooltip
                text="Upload to Library in order to share with others"
                width={300}
              >
                <button
                  // disabled={isPublishButtonDisabled}
                  type="button"
                  className={classNames(classes.saveButton, {
                    [classes.disabled]: isPublishButtonDisabled,
                  })}
                  onClick={() => {
                    if (isPublishButtonDisabled) {
                      return;
                    }
                    if (sections) {
                      if (
                        sections.some(
                          (section) =>
                            section.type === 'video' || section.type === 'audio'
                        )
                      ) {
                        setIsPublishMeetingModalVisible(true);
                      } else {
                        clearTimeout(noContentWarningTimer);
                        setIsNoContentWarningPopupVisible(true);
                        noContentWarningTimer = setTimeout(() => {
                          setIsNoContentWarningPopupVisible(false);
                        }, 3000);
                      }
                    }
                  }}
                >
                  Publish
                </button>
              </Tooltip>
              <Tooltip text="Share your video with others" width={300}>
                <button
                  // disabled={isShareButtonDisabled}
                  type="button"
                  className={classNames(classes.shareButton, {
                    [classes.disabled]: isShareButtonDisabled,
                  })}
                  onClick={() => {
                    if (isShareButtonDisabled) {
                      return;
                    }
                    setIsSelectShareOptionModalVisible(true);
                  }}
                >
                  Share
                </button>
              </Tooltip>
              <Tooltip text="Delete this entire video" width={300}>
                <button
                  // disabled={isDeleteButtonDisabled}
                  type="button"
                  className={classNames(classes.deleteButton, {
                    [classes.disabled]: isDeleteButtonDisabled,
                  })}
                  onClick={() => {
                    if (isDeleteButtonDisabled) {
                      return;
                    }
                    if (meetingId) {
                      showConfirmActionModal({
                        message: 'Are you sure you want to delete this video?',
                        onConfirm: () => deleteMeetingMutation(meetingId),
                      });
                    }
                  }}
                >
                  Delete Video
                </button>
              </Tooltip>
              {fetchedMeeting?.userRole === 'contributor' && (
                <Tooltip
                  text="Notifies video owner of sections you've added"
                  width={300}
                >
                  <button
                    className={classNames(classes.sectionButton, {
                      [classes.disabled]: isSectionButtonDisabled,
                    })}
                    type="button"
                    onClick={() => {
                      if (isSectionButtonDisabled) {
                        return;
                      }

                      closeAssignedSection();
                    }}
                  >
                    Section(s) Complete!
                  </button>
                </Tooltip>
              )}
            </div>
          </div>
          <div className={classes.mediaContainerContainer}>
            <MediaContainer
              setIsSaveSectionPopupVisible={setIsSaveSectionPopupVisible}
              isSaveSectionPopupVisible={isSaveSectionPopupVisible}
              //  saveSectionSuccessMessage={saveSectionSuccessMessage}
              isConfirmSectionRemovalVisible={isConfirmSectionRemovalVisible}
              isCameraEnabled={isCameraEnabled}
              // setIsCameraEnabled={setIsCameraEnabled}
              isScreenEnabled={isScreenEnabled}
              // setIsScreenEnabled={setIsScreenEnabled}
              isAudioEnabled={isAudioEnabled}
              streamPreviewMediaPlayerRef={streamPreviewMediaPlayerRef}
              setIsConfirmSectionRemovalVisible={
                setIsConfirmSectionRemovalVisible
              }
              deleteSection={deleteSection}
              videoPlayerSource={videoPlayerSource}
              setVideoPlayerSource={setVideoPlayerSource}
              startCameraMediaRecorder={startCameraMediaRecorder}
              startScreenMediaRecorder={startScreenMediaRecorder}
              startScreen={startScreen}
              // streamRef={streamRef}
              videoSectionPlayerRef={videoSectionPlayerRef}
              videoPlayerRef={videoPlayerRef}
              mediaRecorderRef={mediaRecorderRef}
              isRecording={isRecording}
              setIsRecording={setIsRecording}
              hasFetchedMeeting={!!fetchedMeeting}
              hidePlaceholder={hidePlaceholder}
              isQuestionFormVisible={isQuestionFormVisible}
              setIsQuestionFormVisible={setIsQuestionFormVisible}
              setQuestion={setQuestion}
              fetchedQuestionData={fetchedQuestionData}
              setFetchedQuestionData={setFetchedQuestionData}
              updateSection={updateSection}
              isAssignSectionFormVisible={isAssignSectionFormVisible}
              meetingId={meetingId}
              hideAssignSectionForm={hideAssignSectionForm}
              showPlaceholder={showPlaceholder}
              createMeeting={createMeetingAsync}
              folderId={folderId}
              meetingTitle={meetingTitle}
              cameraHasStopped={cameraHasStopped}
              setCameraHasStopped={setCameraHasStopped}
              // screenHasStopped={screenHasStopped}
              // setScreenHasStopped={setScreenHasStopped}
              stopVideoPlayer={stopVideoPlayer}
              isPublishMeetingPopupVisible={isPublishMeetingPopupVisible}
              isNoTitleWarningVisible={isNoTitleWarningVisible}
              setIsNoTitleWarningVisible={setIsNoTitleWarningVisible}
              refetchSections={refetch}
              isSectionsCompletePopupVisible={isSectionsCompletePopupVisible}
              setMeetingId={setMeetingId}
              startAudioMediaRecorder={startAudioMediaRecorder}
              isScreenRecordReminderVisible={isScreenRecordReminderVisible}
              setIsScreenRecordReminderVisible={
                setIsScreenRecordReminderVisible
              }
              isSafari={isSafari}
              isShareSuccessPopupVisible={isShareSuccessPopupVisible}
              setIsShareSuccessPopupVisible={setIsShareSuccessPopupVisible}
              stopAllTracks={stopAllTracks}
              isPreviewPopupVisible={isPreparingPreview}
              videoPlayerContainerRef={videoPlayerContainerRef}
              audioImageFileBackground={
                audioImage ? `url(${URL.createObjectURL(audioImage)})` : null
              }
              setAudioImage={setAudioImage}
              isNoContentWarningPopupVisible={isNoContentWarningPopupVisible}
              currentSection={useMemo(() => {
                return sections?.find((sctn) => sctn.id === currentSectionId);
              }, [sections, currentSectionId])}
            />
          </div>
        </div>
      </div>
      <CreateMeetingFooter
        refetchSections={refetch}
        sections={sections}
        setSections={setSections}
        setVideoPlayerSource={setVideoPlayerSource}
        width={width}
        currentSectionId={currentSectionId}
        setCurrentSectionId={setCurrentSectionId}
        setIsConfirmSectionRemovalVisible={setIsConfirmSectionRemovalVisible}
        startVideo={startVideo}
        startAudio={startAudio}
        currentSectionsPage={currentSectionsPage}
        setCurrentSectionsPage={setCurrentSectionsPage}
        isCameraEnabled={isCameraEnabled}
        isScreenEnabled={isScreenEnabled}
        isAudioEnabled={isAudioEnabled}
        isRecording={isRecording}
        updateSection={updateSection}
        firstPlaceholderTitle={firstPlaceholderTitle}
        showPlaceholder={showPlaceholder}
        showQuestionForm={showQuestionForm}
        setIsQuestionFormVisible={setIsQuestionFormVisible}
        isQuestionFormVisible={isQuestionFormVisible}
        setFetchedQuestionData={setFetchedQuestionData}
        showAssignSectionForm={showAssignSectionForm}
        isAssignSectionFormVisible={isAssignSectionFormVisible}
        meeting={fetchedMeeting}
        setIsNoTitleWarningVisible={setIsNoTitleWarningVisible}
        meetingTitle={meetingTitle}
        stopVideoPlayer={stopVideoPlayer}
        hideQuestionAndAssignSectionForms={hideQuestionAndAssignSectionForms}
        hidePlaceholder={hidePlaceholder}
        setIsScreenRecordReminderVisible={setIsScreenRecordReminderVisible}
        sectionTitle={sectionTitle}
        setSectionTitle={setSectionTitle}
        setMeetingId={setMeetingId}
        createMeeting={createMeetingAsync}
        folderId={folderId}
        setFile={setFile}
        createDefaultSectionName={createDefaultSectionName}
        setIsSectionSavedModalVisible={setIsSectionSavedModalVisible}
        setIsSectionCreationStopped={setIsSectionCreationStopped}
        processingProgress={processingProgress}
      />

      <UpdateMeetingTitleModal
        show={isUpdateMeetingTitleModalVisible}
        onConfirm={updateMeetingTitle}
        handleClose={() => setIsUpdateMeetingTitleModalVisible(false)}
        title={meetingTitle}
      />
      <RequestReviewModal
        show={isRequestReviewModalVisible}
        handleClose={() => setIsRequestReviewModalVisible(false)}
        meetingId={meetingId}
      />
      <ShareModal
        show={isShareMeetingModalVisible}
        type="meeting"
        handleClose={() => setIsShareMeetingModalVisible(false)}
        data={fetchedMeeting}
        setIsShareSuccessPopupVisible={setIsShareSuccessPopupVisible}
        selectedTimeZone={selectedTimeZone}
        setIsSelectTimeZoneModalVisible={setIsSelectTimeZoneModalVisible}
        setTimeZoneAssignment={setTimeZoneAssignment}
        openCopyLinkModal={openCopyLinkModal}
      />
      <SelectTimeZoneModal
        show={isSelectTimeZoneModalVisible}
        timeZoneAssignment={timeZoneAssignment}
        selectedTimeZone={selectedTimeZone}
        setSelectedTimeZone={setSelectedTimeZone}
        handleClose={() => setIsSelectTimeZoneModalVisible(false)}
      />
      <CopyLinkModal
        show={isCopyLinkModalVisible}
        handleClose={() => setIsCopyLinkModalVisible(false)}
        sharedMeetingAccess={sharedMeetingAccess}
      />
      <PublishMeetingModal
        show={isPublishMeetingModalVisible}
        handleClose={() => setIsPublishMeetingModalVisible(false)}
        updateMeeting={updateMeeting}
        meetingId={meetingId}
        meetingTitle={meetingTitle}
      />
      <SelectShareOptionModal
        show={isSelectShareOptionModalVisible}
        handleClose={() => setIsSelectShareOptionModalVisible(false)}
        openRequestReviewModal={() => {
          setIsRequestReviewModalVisible(true);
          setIsSelectShareOptionModalVisible(false);
        }}
        openShareMeetingModal={() => {
          setIsShareMeetingModalVisible(true);
          setIsSelectShareOptionModalVisible(false);
        }}
      />
      <AlertModal
        show={isSectionSavedModalVisible}
        message='Your section recording has been saved to "My Saved Sections"'
      />
      <input
        type="file"
        style={{ display: 'none' }}
        onChange={(event) => {
          setFile(event.target.files[0]);
          setIsUpdateMeetingTitleModalVisible(true);
        }}
        ref={fileInputRef}
        accept="video/mp4, video/quicktime, video/x-ms-wmv, video/x-msvideo, video/webm"
      />
    </div>
  );
}
