/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from 'react';

import useOnClickOutside from '../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

const EditableTitle = ({ children: title, handleUpdateTitle, isEditable }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newTitle, setNewTitle] = useState(title);
  const [isEditButtonVisible, setIsEditButtonVisible] = useState(false);

  const inputRef = useRef(null);

  const handleSubmit = async () => {
    try {
      await handleUpdateTitle(newTitle.trim());

      setIsEditing(false);
    } catch (error) {
      console.log(error);
    }
  };

  useOnClickOutside(inputRef, () => handleSubmit());

  const handleClickTitle = (e) => {
    if (!isEditable) {
      return;
    }

    e.stopPropagation();

    if (!newTitle.trim().length) {
      setNewTitle(title);
    }

    setIsEditing(true);
    setTimeout(() => {
      inputRef.current.focus();
    }, 0);
  };

  const handleChangeTitle = (event) => {
    setNewTitle(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div
      onMouseOver={() => setIsEditButtonVisible(true)}
      onMouseLeave={() => setIsEditButtonVisible(false)}
      className={classes.EditableTitle}
    >
      {isEditing ? (
        <input
          ref={inputRef}
          value={newTitle}
          onChange={handleChangeTitle}
          onClick={(e) => e.stopPropagation()}
          onKeyDown={handleKeyPress}
          type="text"
        />
      ) : (
        <>
          <span>{title}</span>
          {isEditable && isEditButtonVisible && (
            <button
              onClick={handleClickTitle}
              type="button"
              className={classes.editButton}
            />
          )}
        </>
      )}
    </div>
  );
};

export default EditableTitle;
