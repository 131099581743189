/* eslint-disable import/no-extraneous-dependencies */
import axios from 'axios';
import { saveAs } from 'file-saver';

const downloadFolder = async (folder) => {
  try {
    const response = await axios.get(`/folders/${folder.id}/download`, {
      responseType: 'blob',
    });
    const blob = new Blob([response.data], { type: 'application/zip' });

    saveAs(blob, `${folder.name}.zip`);
  } catch (error) {
    console.error(error);
  }
};

export default downloadFolder;
