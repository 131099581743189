/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import { useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';

import videoIcon from '../../../assets/images/my-library/video.svg';
import playIcon from '../../../assets/images/my-meetings/play.svg';

import downloadVideo from '../../../helpers/downloadVideo';
import ActionsMenu from '../ActionsMenu';
import formatDuration from '../../../helpers/formatDuration';
import useMeetingProcessing from '../../../hooks/useMeetingProcessing';
import Progress from './Progress';
import { USER_ROLES } from '../../../constants/main';

const MeetingRow = ({
  classes,
  meeting,
  user,
  folderUserRole,
  handleDetails,
  handleDelete,
  handleCopyLink,
}) => {
  const processing = useMeetingProcessing();

  const navigate = useNavigate();

  const meetingUserRole = meeting.MeetingUsers[0].userRole;
  const isMeetingPublished = meeting.status === 'published';
  const isMeetingProcessing = meeting.status === 'in progress';
  const isNotAllowedToView = !isMeetingPublished;

  const isNotAllowedToDelete =
    meeting.status === 'in progress' ||
    (folderUserRole !== 'creator' &&
      folderUserRole !== 'owner' &&
      meeting.userId !== user?.id);

  const isNotAllowedToDownload =
    !isMeetingPublished || user?.role !== USER_ROLES.SUPER_ADMIN;

  const isNotAllowedToEdit =
    meetingUserRole === 'viewer' || meeting.status === 'in progress';

  const isNotAllowedToShare =
    (folderUserRole !== 'creator' &&
      folderUserRole !== 'owner' &&
      meeting.userId !== user?.id) ||
    !isMeetingPublished;

  const isNotAllowedToCopyLink =
    (folderUserRole !== 'creator' &&
      folderUserRole !== 'owner' &&
      meeting.userId !== user?.id) ||
    !isMeetingPublished;

  const isNotAllowedToGetViewerReport =
    !isMeetingPublished || meetingUserRole === 'viewer';

  const duration = formatDuration(meeting.duration);

  const lastPublishedDate = moment(meeting.updatedAt).format('MMM D, YYYY');

  return (
    <tr key={meeting.id}>
      <td className={classes.title}>
        <div className={classes.innerContainer}>
          <img src={videoIcon} alt="Video" />
          <div>
            <span>{meeting.title}</span>
            {isMeetingPublished ? (
              <span className={classes.publishedDate}>
                modified {lastPublishedDate}
              </span>
            ) : (
              <Progress
                isVisible={isMeetingProcessing}
                percentage={processing.percentage}
                step={processing.step}
              />
            )}
          </div>
        </div>
        <button
          className={classes.playButton}
          disabled={isNotAllowedToView}
          type="button"
          onClick={() => navigate(`/my-videos/${meeting.id}`)}
        >
          <img src={playIcon} alt="Play" />
        </button>
      </td>
      <td className={classes.owner}>
        {meeting.userId === user?.id ? 'Me' : meeting.Owner.name}
      </td>
      <td className={classes.publishedDate}>
        {isMeetingProcessing ? 'Processing' : lastPublishedDate}
      </td>
      <td className={classes.buttons}>
        <ActionsMenu
          data={meeting}
          handleDetails={handleDetails}
          handleDelete={handleDelete}
          handleEdit={() => navigate(`/my-videos/${meeting.id}/edit`)}
          handleShare={() =>
            navigate(`/my-videos/${meeting.id}/edit?share=true`)
          }
          handleDownload={() => downloadVideo(meeting)}
          handleGetViewerReport={() =>
            window.open(`/viewer-report/${meeting.id}`, '_blank')
          }
          handleCopyLink={handleCopyLink}
          isNotAllowedToDelete={isNotAllowedToDelete}
          isNotAllowedToEdit={isNotAllowedToEdit}
          isNotAllowedToShare={isNotAllowedToShare}
          isNotAllowedToDownload={isNotAllowedToDownload}
          isNotAllowedToCheckDetails={false}
          isNotAllowedToGetViewerReport={isNotAllowedToGetViewerReport}
          isNotAllowedToCopyLink={isNotAllowedToCopyLink}
        />
        <span className={classes.duration}>{meeting.duration && duration}</span>
        {isNotAllowedToView ? (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                This video is not yet published. To preview, click on the three
                dots to the left and select EDIT and then PREVIEW.
              </Tooltip>
            }
          >
            <button
              disabled={isNotAllowedToView}
              type="button"
              onClick={() => navigate(`/my-videos/${meeting.id}`)}
            >
              <img src={playIcon} alt="Play" />
            </button>
          </OverlayTrigger>
        ) : (
          <button
            className={classes.playButton}
            disabled={isNotAllowedToView}
            type="button"
            onClick={() => navigate(`/my-videos/${meeting.id}`)}
          >
            <img src={playIcon} alt="Play" />
          </button>
        )}
      </td>
    </tr>
  );
};

export default MeetingRow;
