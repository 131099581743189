export const API_URL = process.env.REACT_APP_API_URL;
export const STATIC_URL = process.env.REACT_APP_STATIC_URL;
export const FRONT_URL = process.env.REACT_APP_FRONT_URL;
export const MEETING_STATUSES = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
};
export const DISPLAY_STATUSES = {
  'in progress': 'Processing',
  published: 'Ready to share',
  draft: 'Draft',
};
export const USER_ROLES = {
  SUPER_ADMIN: 'super_admin',
  ADMIN: 'admin',
  USER: 'user',
};
export const MEETING_TABS = {
  MY_VIDEOS: 'my videos',
  MOBILE_UPLOADS: 'mobile uploads',
  SAVED: 'saved',
};
export const WS_URL = process.env.REACT_APP_WS_URL;
