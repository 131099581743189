import React from 'react';
import Modal from 'react-bootstrap/Modal';

import closeIcon from '../../assets/images/closeIcon.svg';

import FilledButton from '../../components/Buttons/FilledButton';
import classes from './styles.module.scss';

export default function AlertModal({
  show,
  hasCloseButton,
  hasConfirmButton,
  title,
  message,
  label,
  handleClose,
  onConfirm,
}) {
  return (
    <Modal
      show={show}
      centered
      contentClassName={classes.AlertModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      {hasCloseButton && (
        <i className={classes.closeIcon} onClick={handleClose}>
          <img src={closeIcon} alt="Close" />
        </i>
      )}
      <div className={classes.content}>
        {title && <h3>{title}</h3>}
        {message && <p>{message}</p>}
        {hasConfirmButton && (
          <FilledButton onClick={onConfirm}>{label}</FilledButton>
        )}
      </div>
    </Modal>
  );
}
