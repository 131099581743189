import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useMutation } from 'react-query';

import classNames from 'classnames';

import FolderService from '../../../services/FolderService';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';
import { UiContext } from '../../../context/UiContext';

const ManageUsersMenu = ({
  folderUser,
  refetchFolderUsers,
  displayedValue,
  user,
  handleClose,
  refetchFolders,
  folder,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [menuLeft, setMenuLeft] = useState(0);
  const [menuTop, setMenuTop] = useState(0);

  const { showConfirmActionModal, notifyError } = useContext(UiContext);

  const toggleButtonRef = useRef(null);
  const menuRef = useRef(null);

  useOnClickOutside(menuRef, () => setIsVisible(false), toggleButtonRef);

  const { mutate: updateFolderUserMutation } = useMutation(
    FolderService.updateFolderUser,
    {
      onSuccess: () => {
        refetchFolderUsers();
        refetchFolders();
      },
      onError: (error) => {
        notifyError(error?.response?.data?.message);
      },
    }
  );

  const { mutate: deleteFolderUserMutation } = useMutation(
    FolderService.deleteFolderUser,
    {
      onSuccess: () => {
        refetchFolderUsers();
      },
    }
  );

  const handleTransferOwnership = useCallback(() => {
    showConfirmActionModal({
      title: 'Make this person the owner?',
      message: `The new owner will be notified, and you will no longer have owner rights to this folder.`,
      onConfirm: () => {
        updateFolderUserMutation({
          folderId: folderUser.folderId,
          userId: folderUser.userId,
          userRole: 'owner',
        });
      },
    });

    handleClose();
  }, [
    folderUser.folderId,
    folderUser.userId,
    handleClose,
    showConfirmActionModal,
    updateFolderUserMutation,
  ]);

  const options = useMemo(
    () => [
      {
        label: 'Viewer',
        value: 'viewer',
        hint: 'Can view files in folders only',
        onClick: () =>
          updateFolderUserMutation({
            folderId: folderUser.folderId,
            userId: folderUser.userId,
            userRole: 'viewer',
          }),
      },
      {
        label: 'Contributor',
        value: 'contributor',
        hint: 'Can view, create and add files and folders',
        onClick: () =>
          updateFolderUserMutation({
            folderId: folderUser.folderId,
            userId: folderUser.userId,
            userRole: 'contributor',
          }),
      },
      {
        label: 'Transfer ownership',
        value: '',
        hint: '',
        onClick: () => handleTransferOwnership(),
      },
      {
        label: 'Remove access',
        value: '',
        hint: '',
        onClick: () =>
          deleteFolderUserMutation({
            folderId: folderUser.folderId,
            userId: folderUser.userId,
          }),
      },
    ],
    [
      deleteFolderUserMutation,
      folderUser.folderId,
      folderUser.userId,
      handleTransferOwnership,
      updateFolderUserMutation,
    ]
  );

  const handleToggleMenu = useCallback(() => {
    if (toggleButtonRef.current) {
      const buttonRect = toggleButtonRef.current.getBoundingClientRect();

      setMenuLeft(buttonRect.left);
      setMenuTop(buttonRect.top + 60);
    }

    setIsVisible((prevState) => !prevState);
  }, []);

  const isManageUsersDisabled =
    user.id !== folder.userId || folderUser.userId === user.id;

  return (
    <div className={classes.ManageUsersMenu}>
      <button
        disabled={isManageUsersDisabled}
        ref={toggleButtonRef}
        onClick={handleToggleMenu}
        type="button"
        className={classNames(classes.toggleButton, {
          [classes.active]: isVisible,
        })}
      >
        {displayedValue}
      </button>
      {isVisible && (
        <div
          ref={menuRef}
          className={classes.menu}
          style={{ left: menuLeft, top: menuTop }}
        >
          <ul className={classes.optionsList}>
            {options.map((option) => (
              <li key={option.label} className={classes.option}>
                <button
                  onClick={() => {
                    option.onClick();
                    setIsVisible(false);
                  }}
                  type="button"
                  className={classNames({
                    [classes.active]: option.value === displayedValue,
                  })}
                >
                  {option.label}
                </button>
                {option.hint && <span>{option.hint}</span>}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ManageUsersMenu;
