/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';

import Modal from 'react-bootstrap/Modal';
import classNames from 'classnames';

import closeIcon from '../../assets/images/closeIcon.svg';
import RoundedInput from '../../components/Inputs/RoundedInput';
import FilledButton from '../../components/Buttons/FilledButton';
import EmojiPickerWithButton from '../../components/EmojiPickerWithButton';
import Textarea from '../../components/Textarea';
import classes from './styles.module.scss';
import validateEmail from '../../helpers/validateEmail';
import MeetingService from '../../services/MeetingService';
import InfoIcon from '../../components/InfoIcon';

export default function RequestReviewModal({ show, handleClose, meetingId }) {
  const [emails, setEmails] = useState('');
  const [message, setMessage] = useState('');
  const [emailsValidationError, setEmailsValidationError] = useState('');

  const handleSubmit = async () => {
    let isValid = true;
    setEmailsValidationError(null);

    let emailsList = [];

    if (!emails) {
      setEmailsValidationError('This field is required');
      isValid = false;
    } else {
      emailsList = emails.split(';').join(',').split(',');
      emailsList = emailsList.map((mail) => mail.trim());

      const isEveryEmailValid = emailsList.every((mail) => validateEmail(mail));

      if (!isEveryEmailValid) {
        setEmailsValidationError('Emails must be valid');
        isValid = false;
      }
    }

    if (isValid) {
      try {
        await MeetingService.requestMeetingReview({
          meetingId,
          emails: emailsList,
          message,
        });

        handleClose();
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (!show) {
      setEmailsValidationError(null);
      setMessage('');
      setEmails('');
    }
  }, [show]);

  return (
    <Modal
      show={show}
      centered
      contentClassName={classes.RequestReviewModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      <i className={classes.closeIcon} onClick={handleClose}>
        <img src={closeIcon} alt="Close" />
      </i>
      <header>
        <h1>Send request to review</h1>
      </header>
      <div className={classes.form}>
        <div className={classNames(classes.row, classes.inviteRow)}>
          <div
            className={classNames(classes.inviteUsersContainer, classes.col)}
          >
            <div className={classes.title}>
              <h2>
                Invite Reviewers
                <div className={classes.infoIconContainer}>
                  <InfoIcon
                    text={`Use a semicolon ";" after each email address when adding
                    multiple emails`}
                    width={285}
                  />
                </div>
              </h2>
              <hr />
            </div>
            <RoundedInput
              type="text"
              placeholder="Add email addresses"
              value={emails}
              onChange={(event) => setEmails(event.target.value)}
            />
            {emailsValidationError && (
              <div className={classes.error}>{emailsValidationError}</div>
            )}
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col}>
            <div className={classes.title}>
              <h2>Add Message</h2>
              <hr />
            </div>
            <div className={classes.messageContainer}>
              <Textarea
                placeholder="Add message..."
                value={message}
                onChange={(event) => setMessage(event.target.value)}
              />
              <EmojiPickerWithButton
                setCommentText={setMessage}
                buttonBottom="24px"
                buttonRight="24px"
                pickerTop="52px"
              />
            </div>
          </div>
        </div>
      </div>
      <footer>
        <FilledButton onClick={handleSubmit}>Send</FilledButton>
      </footer>
    </Modal>
  );
}
