import classNames from 'classnames';
import React, { useState, useEffect, useRef } from 'react';

import classes from './styles.module.scss';

const positions = {
  'top-left': classes.topLeft,
};

export default function Tooltip({
  children,
  show = true,
  text,
  width,
  position,
  textAlign,
}) {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isFixedTooltipVisible, setIsFixedTooltipVisible] = useState(false);
  /* const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0); */

  const tooltipRef = useRef();
  const containerRef = useRef();

  useEffect(() => {
    if (!show) {
      setIsTooltipVisible(false);
    }
  }, [show, isTooltipVisible]);

  useEffect(() => {
    if (isTooltipVisible) {
      const coords = tooltipRef.current?.getBoundingClientRect?.();
      // setLeft(coords?.left);
      // setTop(coords?.top);
      containerRef.current.style.setProperty('--top', `${coords?.top}px`);
      containerRef.current.style.setProperty('--left', `${coords?.left}px`);
      setIsFixedTooltipVisible(true);
    } else {
      setIsFixedTooltipVisible(false);
    }
  }, [isTooltipVisible]);

  return (
    <div
      ref={containerRef}
      className={classNames(classes.Tooltip, positions[position])}
      onMouseEnter={() => {
        setIsTooltipVisible(true);
      }}
      onMouseLeave={() => {
        setIsTooltipVisible(false);
      }}
    >
      {children}
      {isTooltipVisible && (
        <div
          className={classes.hiddenTooltip}
          ref={tooltipRef}
          style={{ width, textAlign, visibility: 'hidden' }}
        >
          {text}
        </div>
      )}
      {isFixedTooltipVisible && (
        <div
          className={classes.visibleTooltip}
          style={{ width, textAlign, position: 'fixed' }}
        >
          {text}
        </div>
      )}
    </div>
  );
}
