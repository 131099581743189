import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';

import searchIcon from '../../assets/images/my-meetings/search.svg';

import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import classes from './styles.module.scss';

export default function SearchBar({ searchInput, setSearchInput }) {
  const isMobile = useCheckMobileScreen();

  const [isActive, setIsActive] = useState(isMobile);

  const inputRef = useRef();
  const toggleRef = useRef();

  const handleChangeSearchInput = (event) => {
    setSearchInput(event.target.value);
  };

  const handleToggleSearchBar = () => {
    if (!isMobile) {
      setIsActive((prev) => !prev);
    }
  };

  useEffect(() => {
    if (isMobile) {
      return;
    }

    if (!isActive) {
      setSearchInput('');
    } else {
      inputRef.current.focus();
    }
  }, [isActive]);

  const placeholder = isMobile ? 'Search in My Videos' : 'Search...';

  return (
    <div
      className={classNames(classes.SearchBar, { [classes.active]: isActive })}
    >
      <input
        ref={inputRef}
        type="text"
        value={searchInput}
        onChange={handleChangeSearchInput}
        placeholder={placeholder}
      />
      <button ref={toggleRef} type="button" onClick={handleToggleSearchBar}>
        <img src={searchIcon} alt="Search" />
      </button>
    </div>
  );
}
