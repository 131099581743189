/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useContext } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import closeIcon from '../../assets/images/closeIcon.svg';
import FilledButton from '../../components/Buttons/FilledButton';
import EmojiPickerWithButton from '../../components/EmojiPickerWithButton';
import Textarea from '../../components/Textarea';
import classes from './styles.module.scss';
import MeetingService from '../../services/MeetingService';
import { UiContext } from '../../context/UiContext';

export default function ConfirmReviewModal({
  show,
  handleClose,
  meetingId,
  reviewId,
  reviewDecision,
  email,
}) {
  const [message, setMessage] = useState('');

  const { notifyError } = useContext(UiContext);

  const navigate = useNavigate();
  const location = useLocation();

  const deleteReviewId = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('reviewId');

    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const handleSubmit = async () => {
    try {
      let decision;

      switch (reviewDecision) {
        case 'approve':
          decision = 'approved';
          break;

        case 'deny':
          decision = 'denied';
          break;

        default:
          break;
      }

      await MeetingService.reviewMeeting({
        meetingId,
        reviewId,
        message,
        decision,
        email,
      });

      deleteReviewId();
      handleClose();
    } catch (error) {
      console.log(error);
      notifyError(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (!show) {
      setMessage('');
    }
  }, [show]);

  return (
    <Modal
      show={show}
      centered
      contentClassName={classes.ConfirmReviewModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      <i className={classes.closeIcon} onClick={handleClose}>
        <img src={closeIcon} alt="Close" />
      </i>
      <header>
        <h1>{reviewDecision} Request</h1>
      </header>
      <div className={classes.form}>
        <div className={classes.title}>
          <h2>Add Message</h2>
          <hr />
        </div>
        <div className={classes.messageContainer}>
          <Textarea
            placeholder="Add message..."
            value={message}
            onChange={(event) => setMessage(event.target.value)}
          />
          <EmojiPickerWithButton
            setCommentText={setMessage}
            buttonBottom="24px"
            buttonRight="24px"
            pickerTop="52px"
          />
        </div>
      </div>
      <footer>
        <FilledButton onClick={handleSubmit}>Send</FilledButton>
      </footer>
    </Modal>
  );
}
