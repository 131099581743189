import React from 'react';

import logo from '../../assets/images/snapvisio.svg';

import classes from './styles.module.scss';

export default function SplitTemplate({ children }) {
  return (
    <div className={classes.SplitTemplate}>
      <div className={classes.leftSide}>{children}</div>

      <div className={classes.rightSide}>
        <img src={logo} alt="SnapVisio" />
      </div>
    </div>
  );
}
