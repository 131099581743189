import React, { useContext } from 'react';

import Modal from 'react-bootstrap/Modal';

import closeIcon from '../../assets/images/closeIcon.svg';
import copyIcon from '../../assets/images/copyIcon.svg';

import { UiContext } from '../../context/UiContext';

import classes from './styles.module.scss';

function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement('textarea');

  textArea.value = text;

  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  document.body.removeChild(textArea);
}

export default function CopyLinkModal({
  show,
  handleClose,
  sharedMeetingAccess,
}) {
  const { notifySuccess } = useContext(UiContext);

  const copyInfo = () => {
    const textToCopy = `To view this prerecorded video, click this link: ${
      sharedMeetingAccess.link
    } ${
      sharedMeetingAccess.security === 'restricted'
        ? `and enter this PASSCODE: ${sharedMeetingAccess.passcode}`
        : ''
    }`;

    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(textToCopy);
      return;
    }

    navigator.clipboard.writeText(textToCopy);
    notifySuccess(
      `${
        sharedMeetingAccess.security === 'restricted'
          ? 'Link and passcode'
          : 'Link'
      } copied to clipboard`
    );
  };

  const linkType =
    sharedMeetingAccess.security === 'public'
      ? 'public link'
      : 'private link and passcode';

  return (
    <Modal
      show={show}
      centered
      contentClassName={classes.CopyLinkModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      <i className={classes.closeIcon} onClick={handleClose}>
        <img src={closeIcon} alt="Close" />
      </i>
      <div className={classes.content}>
        <h3>Here&apos;s your {linkType}</h3>
        <p>
          Click the below card icon to copy and paste your meeting details. Send
          this to people who should view your meeting.
        </p>
        <div className={classes.infoContainer}>
          <div className={classes.info}>
            <a href={sharedMeetingAccess.link} target="_blank" rel="noreferrer">
              LINK
            </a>
            {sharedMeetingAccess.security === 'restricted' && (
              <span>
                <b>PASSCODE:</b> {sharedMeetingAccess.passcode}
              </span>
            )}
          </div>

          <div className={classes.copyIcon} onClick={copyInfo}>
            <img src={copyIcon} alt="Copy" />
          </div>
        </div>
      </div>
    </Modal>
  );
}
