import { useState, useEffect, useContext } from 'react';

import { SocketContext } from '../context';

const useMeetingProcessing = () => {
  const [processing, setProcessing] = useState({
    percentage: 0,
    step: '',
  });

  const { socket } = useContext(SocketContext);

  useEffect(() => {
    const getMeetingProcessing = ({ percentage, step }) => {
      setProcessing({
        percentage,
        step,
      });
    };

    socket.on('processing', getMeetingProcessing);

    return () => {
      socket.removeListener('processing', getMeetingProcessing);
    };
  }, [socket]);

  return processing;
};

export default useMeetingProcessing;
