import React from 'react';

import Modal from 'react-bootstrap/Modal';

import closeIcon from '../../assets/images/closeIcon.svg';

import Tooltip from '../../components/Tooltip';
import FilledButton from '../../components/Buttons/FilledButton';
import classes from './styles.module.scss';

export default function SelectShareOptionModal({
  show,
  handleClose,
  openRequestReviewModal,
  openShareMeetingModal,
}) {
  return (
    <Modal
      show={show}
      centered
      contentClassName={classes.SelectShareOptionModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      <i className={classes.closeIcon} onClick={handleClose}>
        <img src={closeIcon} alt="Close" />
      </i>
      <div className={classes.content}>
        <h3>What do you want to do?</h3>
        <div className={classes.buttons}>
          <Tooltip
            text="Share your SOP video with others to review and approve content"
            width={300}
          >
            <FilledButton onClick={openRequestReviewModal}>
              Send for Review
            </FilledButton>
          </Tooltip>
          <Tooltip text="Share your final SOP video with others" width={300}>
            <FilledButton onClick={openShareMeetingModal}>
              Share my video
            </FilledButton>
          </Tooltip>
        </div>
      </div>
    </Modal>
  );
}
