/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import closeIcon from '../../assets/images/closeIcon.svg';

import { MEETING_STATUSES } from '../../constants/main';
import FilledButton from '../../components/Buttons/FilledButton';
import Textarea from '../../components/Textarea';
import EmojiPickerWithButton from '../../components/EmojiPickerWithButton';
import classes from './styles.module.scss';

export default function PublishMeetingModal({
  show,
  handleClose,
  updateMeeting,
  meetingId,
  meetingTitle,
}) {
  const [comment, setComment] = useState('');

  const handleSubmit = () => {
    updateMeeting({
      meetingId,
      title: meetingTitle,
      comment,
      shouldCreateNewVersion: true,
      status: MEETING_STATUSES.PUBLISHED,
    });
    setComment('');
    handleClose();
  };

  return (
    <Modal
      show={show}
      centered
      contentClassName={classes.PublishMeetingModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      <i className={classes.closeIcon} onClick={handleClose}>
        <img src={closeIcon} alt="Close" />
      </i>
      <div className={classes.form}>
        <h1>Publish video</h1>

        <div className={classes.commentContainer}>
          <Textarea
            placeholder="(Optional) Briefly describe any changes made to your video file"
            value={comment}
            onChange={(event) => setComment(event.target.value)}
          />

          <EmojiPickerWithButton
            setCommentText={setComment}
            buttonBottom="24px"
            buttonRight="24px"
            pickerTop="52px"
          />
        </div>

        <footer className={classes.footer}>
          <FilledButton onClick={handleSubmit}>Publish</FilledButton>
        </footer>
      </div>
    </Modal>
  );
}
