import React from 'react';

import classes from './styles.module.scss';
import FilledButton from '../../Buttons/FilledButton';

export default function VideoApproval({ openConfirmReviewModal }) {
  return (
    <div className={classes.VideoApproval}>
      <div className={classes.buttons}>
        <FilledButton onClick={() => openConfirmReviewModal('approve')}>
          Approve
        </FilledButton>

        <FilledButton onClick={() => openConfirmReviewModal('deny')}>
          Deny
        </FilledButton>
      </div>
    </div>
  );
}
