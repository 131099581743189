import React from 'react';

import EmojiPickerWithButton from '../EmojiPickerWithButton';

import userIcon from '../../assets/images/usericon.webp';
import { STATIC_URL } from '../../constants/main';
import classes from './styles.module.scss';

export default function ReplyBox({
  textareaRef,
  user,
  commentText,
  setCommentText,
  createComment,
  setIsSignUpPopupVisible,
}) {
  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;

    textarea.style.height = '1px';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  return (
    <div className={classes.ReplyBox}>
      <div className={classes.userpic}>
        <img
          src={user?.photoPath ? STATIC_URL + user.photoPath : userIcon}
          alt="Userpic"
        />
      </div>
      <div className={classes.row}>
        <textarea
          ref={textareaRef}
          className={classes.textarea}
          placeholder="Add a comment or ask a question..."
          value={commentText}
          onKeyUp={adjustTextareaHeight}
          onChange={(event) => setCommentText(event.target.value)}
        />
        <EmojiPickerWithButton
          setCommentText={setCommentText}
          buttonBottom={100}
          buttonRight={40}
        />
      </div>
      <button
        type="button"
        className={classes.submitButton}
        onClick={() => {
          setCommentText('');

          if (!user) {
            setIsSignUpPopupVisible(true);
            return;
          }

          const textarea = textareaRef.current;
          textarea.style.height = '1px';

          createComment();
        }}
      >
        Post
      </button>
    </div>
  );
}
