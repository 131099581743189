import React, { useState } from 'react';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import classNames from 'classnames';

import validateEmail from '../../helpers/validateEmail';
import RoundedInput from '../../components/Inputs/RoundedInput';
import Textarea from '../../components/Textarea';
import classes from './styles.module.scss';
import FilledButton from '../../components/Buttons/FilledButton';
import FolderService from '../../services/FolderService';

const VIEWS = {
  REQUEST: 'request',
  SENT: 'sent',
};

export default function RequestFolderAccessPage() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailValidationError, setEmailValidationError] = useState('');
  const [currentView, setCurrentView] = useState(VIEWS.REQUEST);

  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const meetingId = searchParams.get('meetingId');

  const handleSubmit = async () => {
    let isValid = true;
    setEmailValidationError(null);

    if (!email) {
      setEmailValidationError('This field is required');
      isValid = false;
    } else {
      const isEmailValid = validateEmail(email);

      if (!isEmailValid) {
        setEmailValidationError('Emails must be valid');
        isValid = false;
      }
    }

    if (isValid) {
      try {
        await FolderService.requestFolderAccess({
          folderId: id,
          email,
          message,
          meetingId,
        });

        setCurrentView(VIEWS.SENT);
        setTimeout(() => {
          navigate('/my-library');
        }, 2500);
      } catch (error) {
        console.log(error);
      }
    }
  };

  let viewContent = (
    <div className={classes.requestContainer}>
      <h2>You need access</h2>
      <p>Your request will be routed to folder owner for approval</p>
      <form className={classes.form}>
        <div className={classNames(classes.row, classes.emailRow)}>
          <div className={classes.col}>
            <div className={classes.title}>
              <h3>Enter your email</h3>
              <hr />
            </div>
            <div className={classes.emailContainer}>
              <RoundedInput
                type="text"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              {emailValidationError && (
                <div className={classes.error}>{emailValidationError}</div>
              )}
            </div>
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col}>
            <div className={classes.title}>
              <h3>Add Message</h3>
              <hr />
            </div>
            <div className={classes.messageContainer}>
              <Textarea
                placeholder="Add message (optional)"
                value={message}
                onChange={(event) => setMessage(event.target.value)}
              />
            </div>
          </div>
        </div>
      </form>
      <footer>
        <FilledButton onClick={handleSubmit}>Request access</FilledButton>
      </footer>
    </div>
  );

  if (currentView === VIEWS.SENT) {
    viewContent = (
      <div className={classes.sentContainer}>
        <h2>Request sent</h2>
        <p>
          You&apos;ll get an email letting you know if your request was
          approved.
        </p>
      </div>
    );
  }

  return (
    <div className={classes.RequestFolderAccessPage}>
      <header>
        <h1>SnapVisio</h1>
      </header>
      {viewContent}
    </div>
  );
}
