/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef, useContext } from 'react';

import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import InfoIcon from '../../InfoIcon';
import cameraIcon from '../../../assets/images/section-menu/camera.svg';
import selfIcon from '../../../assets/images/section-menu/self.svg';
import desktopIcon from '../../../assets/images/section-menu/desktop.svg';
import questionIcon from '../../../assets/images/section-menu/question.svg';
import assignIcon from '../../../assets/images/section-menu/assign.svg';
import audioIcon from '../../../assets/images/section-menu/audio.svg';
import notesIcon from '../../../assets/images/section-menu/notes.svg';
import importIcon from '../../../assets/images/section-menu/import.svg';

import { UiContext } from '../../../context/UiContext';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';
import Tooltip from '../../Tooltip';
import ImportFileModal from '../../../modals/ImportFileModal';

export default function SectionMenu({
  onCamera,
  onScreen,
  isScreenEnabled,
  isCameraEnabled,
  isRecording,
  showPlaceholder,
  // hidePlaceholder,
  onQuestion,
  setIsQuestionFormVisible,
  isQuestionFormVisible,
  onAssignSection,
  isAssignSectionFormVisible,
  meeting,
  meetingTitle,
  setIsNoTitleWarningVisible,
  isAudioEnabled,
  onAudio,
  sections,
  createMeeting,
  folderId,
  setMeetingId,
  refetchSections,
  setFile,
  setSectionTitle,
  createDefaultSectionName,
}) {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [leftPosition, setLeftPosition] = useState(0);
  const [isRecordSubmenuVisible, setIsRecordSubmenuVisible] = useState(false);
  const [isImportFileModalVisible, setIsImportFileModalVisible] =
    useState(false);

  const menuRef = useRef();
  const toggleRef = useRef();
  const fileInputRef = useRef();

  const navigate = useNavigate();

  useOnClickOutside(menuRef, () => setIsMenuVisible(false), toggleRef);

  const { showMeetingNotesModal } = useContext(UiContext);

  useEffect(() => {
    if (isMenuVisible) {
      const menuCoords = menuRef.current.getBoundingClientRect();

      if (menuCoords.left + menuRef.current.clientWidth >= window.innerWidth) {
        setLeftPosition(window.innerWidth - menuRef.current.clientWidth - 16);
      } else {
        const coords = toggleRef.current.getBoundingClientRect();
        setLeftPosition(coords.left + 120);
      }
    } else {
      setIsRecordSubmenuVisible(false);
    }
  }, [isMenuVisible, menuRef.current?.clientWidth]);

  const lastSection = sections?.[sections.length - 1];

  const isMenuBlocked =
    isCameraEnabled ||
    isRecording ||
    isScreenEnabled ||
    isAudioEnabled ||
    (isQuestionFormVisible && lastSection?.placeholderType === 'question') ||
    isAssignSectionFormVisible ||
    meeting?.userRole === 'contributor-closed';

  const isAssignSectionButtonDisabled = meeting?.userRole === 'contributor';

  const showRecordSubmenu = () => {
    setIsRecordSubmenuVisible(true);
  };

  const hideRecordSubmenu = () => {
    setIsRecordSubmenuVisible(false);
  };

  const handleMyComputerClick = () => {
    fileInputRef.current.click();

    const sectionTitle = createDefaultSectionName({
      placeholderType: 'video',
    });

    setSectionTitle(sectionTitle);
  };

  return (
    <div
      className={classNames(
        classes.SectionMenu,
        !sections?.length && classes.emptyList
      )}
    >
      <Tooltip
        show={!isMenuVisible}
        text="Choose different ways to record content for your video"
        width={300}
      >
        <div
          className={classes.toggle}
          onClick={() => setIsMenuVisible((prevState) => !prevState)}
          ref={toggleRef}
        >
          <svg
            width="100"
            height="100"
            viewBox="0 0 140 140"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M99.6719 63.6719H76.3281V40.3281C76.3281 36.8332 73.495 34 70 34C66.505 34 63.6719 36.8332 63.6719 40.3281V63.6719H40.3281C36.8332 63.6719 34 66.505 34 70C34 73.495 36.8332 76.3281 40.3281 76.3281H63.6719V99.6719C63.6719 103.167 66.505 106 70 106C73.495 106 76.3281 103.167 76.3281 99.6719V76.3281H99.6719C103.167 76.3281 106 73.495 106 70C106 66.505 103.167 63.6719 99.6719 63.6719Z"
              fill="#f8585c"
            />
          </svg>
        </div>
      </Tooltip>
      {isMenuVisible && (
        <div
          className={classes.menu}
          ref={menuRef}
          style={{ left: leftPosition }}
        >
          <ul>
            <li
              className={classNames({
                [classes.disabled]: isMenuBlocked,
              })}
              onMouseEnter={showRecordSubmenu}
              onMouseLeave={hideRecordSubmenu}
            >
              <span className={classes.iconContainer}>
                <img src={cameraIcon} alt="Camera" />
              </span>
              Record options
              {isRecordSubmenuVisible && (
                <div
                  className={classes.subMenu}
                  onMouseEnter={showRecordSubmenu}
                  onMouseLeave={hideRecordSubmenu}
                >
                  <ul>
                    <li
                      className={classNames({
                        [classes.disabled]: isMenuBlocked,
                      })}
                      onClick={() => {
                        if (isMenuBlocked) {
                          return;
                        }

                        if (!meetingTitle.trim()) {
                          setIsNoTitleWarningVisible(true);
                          setIsMenuVisible(false);
                          return;
                        }

                        if (!isCameraEnabled && !isRecording) {
                          onCamera();
                        }

                        if (!isRecording) {
                          showPlaceholder('', 'video');
                        }

                        setIsMenuVisible(false);
                        setIsQuestionFormVisible(false);
                      }}
                    >
                      <span className={classes.iconContainer}>
                        <img src={selfIcon} alt="Self" />
                      </span>
                      Record self
                    </li>
                    <li
                      className={classNames({
                        [classes.disabled]: isMenuBlocked,
                      })}
                      onClick={() => {
                        if (isMenuBlocked) {
                          return;
                        }

                        if (!meetingTitle.trim()) {
                          setIsNoTitleWarningVisible(true);
                          setIsMenuVisible(false);
                          return;
                        }

                        if (!isScreenEnabled && !isRecording) {
                          onScreen();
                        }
                        if (!isRecording) {
                          showPlaceholder('', 'video');
                        }
                        setIsMenuVisible(false);
                        setIsQuestionFormVisible(false);
                      }}
                    >
                      <span className={classes.iconContainer}>
                        <img src={desktopIcon} alt="Desktop" />
                      </span>
                      Record screen
                    </li>
                    <li
                      className={classNames({
                        [classes.disabled]: isMenuBlocked,
                      })}
                      onClick={() => {
                        if (isMenuBlocked) {
                          return;
                        }

                        if (!meetingTitle.trim()) {
                          setIsNoTitleWarningVisible(true);
                          setIsMenuVisible(false);
                          return;
                        }

                        if (!isAudioEnabled && !isRecording) {
                          onAudio();
                        }
                        if (!isRecording) {
                          showPlaceholder('', 'audio');
                        }
                        setIsMenuVisible(false);
                        setIsQuestionFormVisible(false);
                      }}
                    >
                      <span className={classes.iconContainer}>
                        <img src={audioIcon} alt="Audio" />
                      </span>
                      Record audio
                    </li>
                  </ul>
                </div>
              )}
            </li>
            <li
              className={classNames({
                [classes.disabled]: isMenuBlocked,
              })}
              onClick={() => {
                if (isMenuBlocked) {
                  return;
                }

                if (!meetingTitle.trim()) {
                  setIsNoTitleWarningVisible(true);
                  setIsMenuVisible(false);
                  return;
                }

                onQuestion();
                showPlaceholder('', 'question');
                setIsMenuVisible(false);
              }}
            >
              <span className={classes.iconContainer}>
                <img src={questionIcon} alt="Question" />
              </span>
              <div className={classes.nameContainer}>
                Add a question{' '}
                <InfoIcon
                  text="Add a question anywhere in your meeting to capture feedback from your viewers"
                  width={320}
                />
              </div>
            </li>
            <li
              className={classNames({
                [classes.disabled]:
                  isMenuBlocked || isAssignSectionButtonDisabled,
              })}
              onClick={() => {
                if (isMenuBlocked || isAssignSectionButtonDisabled) {
                  return;
                }

                if (!meetingTitle.trim()) {
                  setIsNoTitleWarningVisible(true);
                  setIsMenuVisible(false);
                  return;
                }

                onAssignSection();
                setIsMenuVisible(false);
              }}
            >
              <span className={classes.iconContainer}>
                <img src={assignIcon} alt="Assign" />
              </span>
              <div className={classes.nameContainer}>
                Assign a Section{' '}
                <InfoIcon
                  text="Invite others to create content for your video"
                  width={320}
                />
              </div>
            </li>
            <li
              className={classNames({
                [classes.disabled]: isMenuBlocked,
              })}
              onClick={async () => {
                if (isMenuBlocked) {
                  return;
                }

                if (!meetingTitle.trim()) {
                  setIsNoTitleWarningVisible(true);
                  setIsMenuVisible(false);
                  return;
                }

                const noteSectionCount = sections?.filter(
                  (section) => section.type === 'note'
                ).length;

                if (!meeting) {
                  const newMeeting = await createMeeting({
                    title: meetingTitle,
                    folderId,
                  });
                  const { id } = newMeeting;
                  setMeetingId(id);

                  navigate(`/my-videos/${id}/edit`, { replaceState: true });
                  showMeetingNotesModal(refetchSections, noteSectionCount);
                  return;
                }

                showMeetingNotesModal(refetchSections, noteSectionCount);
              }}
            >
              <span className={classes.iconContainer}>
                <img src={notesIcon} alt="Notes" />
              </span>
              Add Text
            </li>
            <li
              className={classNames({
                [classes.disabled]: isMenuBlocked,
              })}
              onClick={() => {
                if (isMenuBlocked) {
                  return;
                }

                setIsImportFileModalVisible(true);
                setIsMenuVisible(false);
              }}
            >
              <span className={classes.iconContainer}>
                <img src={importIcon} alt="Import video file" />
              </span>
              <div className={classes.nameContainer}>
                Import video file{' '}
                <InfoIcon
                  text="Add your own video files. Supported formats: MP4, MOV, WMV, AVI, WebM"
                  width={320}
                />
              </div>
            </li>
          </ul>
        </div>
      )}
      <input
        type="file"
        style={{ display: 'none' }}
        onChange={(event) => setFile(event.target.files[0])}
        ref={fileInputRef}
        accept="video/mp4, video/quicktime, video/x-ms-wmv, video/x-msvideo, video/webm"
      />

      <ImportFileModal
        show={isImportFileModalVisible}
        handleClose={() => setIsImportFileModalVisible(false)}
        onMobileUploadsClick={() =>
          navigate(`/my-videos?tab=mobile uploads&meetingId=${meeting.id}`)
        }
        onSavedSectionsClick={() =>
          navigate(`/my-videos?tab=saved&meetingId=${meeting.id}`)
        }
        onMyComputerClick={handleMyComputerClick}
      />
    </div>
  );
}
