import React from 'react';
import { useNavigate } from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';

import closeIcon from '../../assets/images/closeIcon.svg';
import FilledButton from '../../components/Buttons/FilledButton';

import classes from './styles.module.scss';

export default function ShareMeetingSuccessModal({ show, handleClose }) {
  const navigate = useNavigate();

  return (
    <Modal
      onHide={handleClose}
      show={show}
      centered
      contentClassName={classes.ShareMeetingSuccessModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      <div className={classes.content}>
        <i className={classes.closeIcon} onClick={handleClose}>
          <img src={closeIcon} alt="Close" />
        </i>
        <h1>Your video has been shared!</h1>
        <p>You will be notified via email when someone views your video</p>
        <div className={classes.buttons}>
          <FilledButton
            width={240}
            onClick={() => {
              handleClose();
              navigate('/my-library');
            }}
          >
            Go to Library
          </FilledButton>
        </div>
      </div>
    </Modal>
  );
}
