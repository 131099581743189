import { FRONT_URL } from '../constants/main';

const copyFolderLink = (folderId, meetingId) => {
  let url = `${FRONT_URL}/my-library/${folderId}`;

  if (meetingId) {
    url += `?meetingId=${meetingId}`;
  }

  navigator.clipboard.writeText(url);
};

export default copyFolderLink;
