/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import Pagination from 'react-js-pagination';
import { useDebounce } from 'use-debounce';
import moment from 'moment';

import { UiContext } from '../../context/UiContext';
import useConfirmDelete from '../../hooks/useConfirmDelete';
import UserService from '../../services/UserService';
import FolderService from '../../services/FolderService';
import MeetingService from '../../services/MeetingService';
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import Breadcrumbs from '../../components/Breadcrumbs';
import SearchBar from '../../components/SearchBar';
import FilledButton from '../../components/Buttons/FilledButton';
import CreateFolderMenu from '../../components/Library/CreateFolderMenu';
import FolderRow from '../../components/Library/FolderRow';
import MeetingRow from '../../components/Library/MeetingRow';
import Subfolder from '../../components/Library/Subfolder';
import MeetingDetailsModal from '../../modals/MeetingDetailsModal';
import ShareModal from '../../modals/ShareModal';
import FolderUsersModal from '../../modals/FolderUsersModal';
import copyFolderLink from '../../helpers/copyFolderLink';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import classes from './styles.module.scss';
import downloadFolder from '../../helpers/downloadFolder';
import { USER_ROLES } from '../../constants/main';

const rowHeight = 70;
const restHeight = 112 + 108 + 24 + 64;
const mobileItemsRange = Math.floor(
  (window.innerHeight - restHeight) / rowHeight
);

export default function LibraryPage() {
  const [page, setPage] = useState(1);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [processingMeetings, setProcessingMeetings] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [debouncedSearchInput] = useDebounce(searchInput, 500);
  const [isCreateFolderMenuVisible, setIsCreateFolderMenuVisible] =
    useState(false);
  const [isShareFolderModalVisible, setIsShareFolderModalVisible] =
    useState(false);
  const [isMeetingDetailsModalVisible, setIsMeetingDetailsModal] =
    useState(false);
  const [isFolderUsersModalVisible, setIsFolderUsersModalVisible] =
    useState(false);

  const { notifySuccess, savedPageNumber, setSavedPageNumber } =
    useContext(UiContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const isMobile = useCheckMobileScreen();

  const addButtonRef = useRef(null);
  const queryParams = new URLSearchParams(location.search);
  const isShare = queryParams.get('share');

  const itemsRange = isMobile ? mobileItemsRange : 5;

  const { data: user } = useQuery('me', UserService.getMe);

  const {
    data: folders,
    refetch: refetchFolders,
    isFetching: areFoldersFetching,
  } = useQuery(
    ['folders', page, debouncedSearchInput],
    () =>
      FolderService.getFolders({
        page,
        search: debouncedSearchInput,
        limit: itemsRange,
      }),
    {
      keepPreviousData: true,
      enabled: !id,
    }
  );

  const {
    data: openedFolder,
    refetch: refetchFolder,
    isFetching: isFolderFetching,
  } = useQuery(
    ['folders', id, debouncedSearchInput],
    () => FolderService.getFolder(id, debouncedSearchInput),
    {
      keepPreviousData: true,
      refetchInterval: processingMeetings.length ? 2500 : 0,
      enabled: !!id,
      retry: false,
      onError: (error) => {
        if (error?.response?.status === 403) {
          const meetingId = queryParams.get('meetingId');

          navigate(
            `/request-folder-access/${id}${
              meetingId ? `?meetingId=${meetingId}` : ''
            }`,
            { replace: true }
          );
        }
      },
    }
  );

  const { mutate: deleteFolderMutation } = useMutation(
    FolderService.deleteFolder,
    {
      onSuccess: () => {
        if (openedFolder) {
          refetchFolder();
        } else {
          refetchFolders();
        }
      },
    }
  );

  const { mutate: deleteMeetingMutation } = useMutation(
    MeetingService.deleteMeeting,
    {
      onSuccess: () => {
        refetchFolder();
      },
    }
  );

  const { mutate: updateFolderMutation } = useMutation(
    FolderService.updateFolder,
    {
      onSuccess: () => {
        if (openedFolder) {
          refetchFolder();
        } else {
          refetchFolders();
        }
      },
    }
  );

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);

  useEffect(() => {
    const meetings = openedFolder?.meetings;

    if (meetings?.length) {
      setProcessingMeetings(
        meetings.filter((meeting) => meeting.status === 'in progress')
      );
    }
  }, [openedFolder]);

  useEffect(() => {
    const isValidShareRootFolder =
      isShare === 'true' && id && openedFolder?.data.Parent === null;

    if (isValidShareRootFolder) {
      setIsShareFolderModalVisible(true);
      setSelectedFolder(openedFolder?.data);
    }
  }, [id, isShare, openedFolder?.data]);

  useEffect(() => {
    if (debouncedSearchInput) {
      setPage(1);
    }
  }, [debouncedSearchInput]);

  useEffect(() => {
    if (savedPageNumber) {
      setPage(savedPageNumber);
    }

    return () => {
      if (id) {
        setSavedPageNumber(null);
      }
    };
  }, [id, savedPageNumber, setSavedPageNumber]);

  const switchPage = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleDetailsMeeting = useCallback((meeting) => {
    setSelectedMeeting(meeting);
    setIsMeetingDetailsModal(true);
  }, []);

  const handleDeleteFolder = useConfirmDelete(deleteFolderMutation, 'folder');
  const handleDeleteMeeting = useConfirmDelete(deleteMeetingMutation, 'video');

  const handleShareFolder = useCallback((folder) => {
    setSelectedFolder(folder);
    setIsShareFolderModalVisible(true);
  }, []);

  const handleManageFolderUsers = useCallback((folder) => {
    setSelectedFolder(folder);
    setIsFolderUsersModalVisible(true);
  }, []);

  const handleUpdateTitle = useCallback(
    (folderId, newTitle) => {
      const trimmedTitle = newTitle.trim();

      if (!trimmedTitle.length) {
        return;
      }

      updateFolderMutation({ folderId, name: trimmedTitle });
    },
    [updateFolderMutation]
  );

  const handleCopyFolderLink = useCallback(
    (folderId, meetingId) => {
      copyFolderLink(folderId, meetingId);
      notifySuccess(`Link copied to clipboard`);
    },
    [notifySuccess]
  );

  const isLoading = areFoldersFetching || isFolderFetching;
  const breadcrumbsOptions = [{ label: 'My Library', to: '/my-library' }];

  let subfoldersContent;
  let tableContent;

  if (folders) {
    tableContent = folders.rows.map((fdr) => (
      <FolderRow
        key={fdr.id}
        onClick={() => {
          navigate(`/my-library/${fdr.id}`);
          setSavedPageNumber(page);
        }}
        classes={classes}
        folder={fdr}
        user={user}
        onUpdateTitle={(newTitle) => handleUpdateTitle(fdr.id, newTitle)}
        handleDelete={() => handleDeleteFolder(fdr)}
        handleShare={() => handleShareFolder(fdr)}
        handleManageUsers={() => handleManageFolderUsers(fdr)}
        handleCopyLink={() => handleCopyFolderLink(fdr.id)}
      />
    ));
  }

  if (openedFolder) {
    const userRole = openedFolder?.data.userRole;

    subfoldersContent = openedFolder.subfolders.map((subfolder) => {
      const isNotAllowedToDeleteOrShare =
        userRole !== 'owner' &&
        userRole !== 'creator' &&
        subfolder.Owner.id !== user?.id;
      const isNotAllowedToCopyLink = userRole === 'viewer';
      const isTitleEditable =
        userRole === 'owner' ||
        userRole === 'creator' ||
        subfolder.userId === user?.id;

      return (
        <li className={classes.subfolderContainer} key={subfolder.id}>
          <Subfolder
            title={subfolder.name}
            onClick={() => navigate(`/my-library/${subfolder.id}`)}
            isTitleEditable={isTitleEditable}
            onUpdateTitle={(newTitle) =>
              handleUpdateTitle(subfolder.id, newTitle)
            }
            hasActionsMenu
            actionsMenuProps={{
              handleShare: () => handleShareFolder(subfolder),
              handleDelete: () => handleDeleteFolder(subfolder),
              handleCopyLink: () => handleCopyFolderLink(subfolder.id),
              handleDownload: () => downloadFolder(subfolder),
              isNotAllowedToDelete: isNotAllowedToDeleteOrShare,
              isNotAllowedToShare: isNotAllowedToDeleteOrShare,
              isNotAllowedToCopyLink,
              isNotAllowedToDownload: user?.role !== USER_ROLES.SUPER_ADMIN,
            }}
            lastPublishedDate={moment(subfolder.updatedAt).format(
              'MMM D, YYYY'
            )}
          />
        </li>
      );
    });

    tableContent = openedFolder.meetings.map((meeting) => (
      <MeetingRow
        key={meeting.id}
        classes={classes}
        meeting={meeting}
        folderUserRole={openedFolder?.data.userRole}
        user={user}
        handleDetails={() => handleDetailsMeeting(meeting)}
        handleDelete={() => handleDeleteMeeting(meeting)}
        handleCopyLink={() =>
          handleCopyFolderLink(meeting.folderId, meeting.id)
        }
      />
    ));

    const formattedBreadcrumbs = openedFolder.parentFolders.map((folder) => ({
      label: folder.name,
      to: `/my-library/${folder.id}`,
    }));

    breadcrumbsOptions.push(...formattedBreadcrumbs);
  }

  const isEmpty = id
    ? !openedFolder?.subfolders.length && !openedFolder?.meetings.length
    : !folders?.rows.length;

  return (
    <div className={classes.LibraryPage}>
      <Header
        hasUserBlock
        hasSearch={isMobile}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
      />
      <Navigation />
      <div className={classes.container}>
        <div className={classes.libraryHeader}>
          <div>
            <Breadcrumbs breadcrumbsOptions={breadcrumbsOptions} />
            {openedFolder && <h2>{openedFolder.data.name}</h2>}
          </div>

          <div className={classes.buttonsContainer}>
            <SearchBar
              searchInput={searchInput}
              setSearchInput={setSearchInput}
            />
            <div ref={addButtonRef}>
              <FilledButton
                onClick={() =>
                  setIsCreateFolderMenuVisible((prevState) => !prevState)
                }
              >
                + Add new
              </FilledButton>
            </div>
            {isCreateFolderMenuVisible && (
              <CreateFolderMenu
                setIsVisible={setIsCreateFolderMenuVisible}
                refetchFolders={refetchFolders}
                refetchFolder={refetchFolder}
                addButtonRef={addButtonRef}
                folder={openedFolder?.data}
                user={user}
              />
            )}
          </div>
        </div>
        {!!subfoldersContent?.length && (
          <ul className={classes.subfolders}>{subfoldersContent}</ul>
        )}
        {!!tableContent?.length && (
          <div className={classes.tableContainer}>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Owner</th>
                  <th>Modified Date</th>
                </tr>
              </thead>
              <tbody>{tableContent}</tbody>
            </table>
          </div>
        )}

        {!isLoading && isEmpty && (
          <p className={classes.empty}>This folder is empty</p>
        )}

        {!id && folders?.count > itemsRange && (
          <div className={classes.paginationContainer}>
            <Pagination
              itemClass={classes.button}
              innerClass={classes.buttonsList}
              activeClass={classes.active}
              disabledClass={classes.disabled}
              activePage={page}
              itemsCountPerPage={itemsRange}
              totalItemsCount={folders?.count}
              pageRangeDisplayed={itemsRange}
              prevPageText="Prev"
              nextPageText="Next"
              onChange={switchPage}
            />
          </div>
        )}
      </div>

      <MeetingDetailsModal
        meeting={selectedMeeting}
        show={isMeetingDetailsModalVisible}
        handleClose={() => setIsMeetingDetailsModal(false)}
      />
      <ShareModal
        type={selectedFolder?.parentId ? 'subfolder' : 'folder'}
        data={selectedFolder}
        show={isShareFolderModalVisible}
        handleClose={() => setIsShareFolderModalVisible(false)}
      />
      <FolderUsersModal
        folder={selectedFolder}
        show={isFolderUsersModalVisible}
        handleClose={() => setIsFolderUsersModalVisible(false)}
        handleShare={handleShareFolder}
        user={user}
        refetchFolders={refetchFolders}
      />
    </div>
  );
}
