import React from 'react';

import moment from 'moment';

import folderIcon from '../../../assets/images/my-library/folder.svg';

import ActionsMenu from '../ActionsMenu';
import EditableTitle from '../EditableTitle';
import downloadFolder from '../../../helpers/downloadFolder';
import { USER_ROLES } from '../../../constants/main';

const FolderRow = ({
  folder,
  onClick,
  user,
  onUpdateTitle,
  handleDelete,
  handleShare,
  handleManageUsers,
  handleCopyLink,
  classes,
}) => {
  const isOwnerOrCreator =
    folder.userRole === 'owner' || folder.userRole === 'creator';
  const isNotAllowedToDeleteOrShare =
    folder.userRole !== 'owner' &&
    folder.userRole !== 'creator' &&
    folder.Owner.id !== user?.id;
  const isNotAllowedToCopyLink = folder.userRole === 'viewer';
  const isNotAllowedToManageUsers = !!folder.parentId;
  const isNotAllowedToDownload = user?.role !== USER_ROLES.SUPER_ADMIN;
  const lastPublishedDate = moment(folder.updatedAt).format('MMM D, YYYY');

  return (
    <tr key={folder.id} onClick={onClick} style={{ cursor: 'pointer' }}>
      <td className={classes.title}>
        <div className={classes.innerContainer}>
          <img src={folderIcon} alt="Folder" />
          <div>
            <EditableTitle
              handleUpdateTitle={onUpdateTitle}
              isEditable={isOwnerOrCreator}
            >
              {folder.name}
            </EditableTitle>
            <span className={classes.publishedDate}>
              modified {lastPublishedDate}
            </span>
          </div>
        </div>
      </td>
      <td className={classes.owner}>
        {folder.userId === user?.id ? 'Me' : folder.Owner.name}
      </td>
      <td className={classes.publishedDate}>{lastPublishedDate}</td>
      <td className={classes.buttons}>
        <ActionsMenu
          handleDelete={handleDelete}
          handleShare={handleShare}
          handleManageUsers={handleManageUsers}
          handleCopyLink={handleCopyLink}
          handleDownload={() => downloadFolder(folder)}
          isNotAllowedToDelete={isNotAllowedToDeleteOrShare}
          isNotAllowedToShare={isNotAllowedToDeleteOrShare}
          isNotAllowedToManageUsers={isNotAllowedToManageUsers}
          isNotAllowedToCopyLink={isNotAllowedToCopyLink}
          isNotAllowedToDownload={isNotAllowedToDownload}
        />
      </td>
    </tr>
  );
};

export default FolderRow;
